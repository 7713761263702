import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampaignDetalInterface, CampaignFieldsInterface, CampaignInterface, IndividualDateInterface} from '../../Models/campaign-interface';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CampaingService} from '../../services/campaing.service';
import {EmployeeAllInterface, EmployeeFieldsInterface, EmployeeIndividualInterface} from '../../Models/employee-interface';
import {PropertyInterface, RolesInterface} from '../../Models/property-interface';
import {EmployeesService} from '../../services/employees.service';
@Component({
  selector: 'app-table-employees-all',
  templateUrl: './table-employees-all.component.html',
  styleUrls: ['./table-employees-all.component.scss']
})
export class TableEmployeesAllComponent implements OnInit {

  @Input() userList: EmployeeAllInterface[];
  @Input() userListOriginal: EmployeeAllInterface[];
  @Input() propertyModel: PropertyInterface;
  @Input() rolesList: RolesInterface[];
  @Output() employeeChange = new EventEmitter();
  public hiModal = false;
  public hiModalDelete = false;
  public txtModalDelete = '';
  public agreeMode = false;
  public startDate: NgbDateStruct;
  public endDate: IndividualDateInterface;
  public editMode: boolean;
  public page = 1;
  public individualEmployee: EmployeeIndividualInterface;
  public individualEmployeeFields: EmployeeFieldsInterface;
  public searchInput = '';
  public userSelected: EmployeeAllInterface;

  constructor(private campaignService: CampaingService, private employeeService: EmployeesService) { }
  ngOnInit() {
    this.initVar();
  }
  public initVar() {
    this.editMode = false;

    this.individualEmployee = {
      active: true,
      departmentId: null,
      email: '',
      employId: '',
      firstName: '',
      id: null,
      jobId: null,
      lastName: '',
      managerId: null,
      password: '',
      positionId: null,
      role: '',
      workplaceId: null
    };
    this.individualEmployeeFields = {
      departmentId: false,
      email: false,
      employId: false,
      firstName: false,
      jobId: false,
      lastName: false,
      managerId: false,
      positionId: false,
      role: false,
      workplaceId: false,
      password: false
    };
  }
  public hideModal() {
    this.hiModal = !this.hiModal;
  }

  public hideModalAgree(user: EmployeeAllInterface, agree: boolean) {
    this.hiModalDelete = !this.hiModalDelete;
    this.agreeMode = agree;
    if (this.agreeMode) {
      this.txtModalDelete = '¿Desea habilitar al usuario?';
    } else {
      this.txtModalDelete = '¿Está seguro que desea deshabilitar al usuario?';
    }
    this.userSelected = user;
  }
  postEdit() {
    if (this.validData() === 0) {
      if (!this.editMode) {
        this.employeeService.createEmployee(this.individualEmployee).subscribe(resp => {
          this.hideModal();
          this.initVar();
          this.employeeChange.emit(true);
        });
      } else {
        this.employeeService.editEmployee(this.individualEmployee).subscribe(resp => {
          this.hideModal();
          this.initVar();
          this.employeeChange.emit(true);
        });
      }
    }
  }
  validData() {
    let errors = 0;
    const keys = Object.keys(this.individualEmployeeFields);
    keys.forEach(key => {
      if (this.individualEmployee[key] === null || this.individualEmployee[key] === '') {
        if ( key === 'password' && this.editMode) {
        } else {

          errors++;
          this.individualEmployeeFields[key] = true;
        }
      } else {
        this.individualEmployeeFields[key] = false;
      }
    });
    return errors;
  }

  public typed(field: string): void {

    setTimeout(() => {

      const el: HTMLElement = document.getElementById(field).getElementsByClassName('ui-select-choices dropdown-menu')[0] as HTMLElement;
      if (typeof el !== 'undefined') {
        el.style.display = 'block';
      }
    }, 100);
  }


  public editUser(user: EmployeeAllInterface) {
    this.hideModal();
    this.editMode = true;
    this.individualEmployee = {
      'active': user.active,
      'departmentId': user.departmentId,
      'email': user.email,
      'employId': user.employId,
      'firstName': user.firstName,
      'id': user.id,
      'jobId': user.jobId,
      'lastName': user.lastName,
      'managerId': user.managerId,
      'password': '',
      'positionId': user.positionId,
      'role': user.role,
      'workplaceId': user.workplaceId
    };
  }

  public deleteUser(user: EmployeeAllInterface) {
    user.active = this.agreeMode;
    this.individualEmployee = {
      'active': user.active,
      'departmentId': user.departmentId,
      'email': user.email,
      'employId': user.employId,
      'firstName': user.firstName,
      'id': user.id,
      'jobId': user.jobId,
      'lastName': user.lastName,
      'managerId': user.managerId,
      'password': '',
      'positionId': user.positionId,
      'role': user.role,
      'workplaceId': user.workplaceId
    };

    this.employeeService.editEmployee(this.individualEmployee).subscribe(resp => {
      this.employeeChange.emit(true);
      this.hideModalAgree(this.userSelected, this.agreeMode);
    });
  }

  public searchUser() {
    this.userList = this.userListOriginal.filter( user => {
      if (user.fullName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.departmentName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.jobName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.positionName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.workplaceName.toLowerCase().includes(this.searchInput.toLowerCase())) {
        return user;
      }
    });
  }

  public getCreatedBtn() {
    if (this.editMode) {
      return 'Editar';
    } else {
      return 'Crear';
    }
  }

  public getTitleModal() {
    if (this.editMode) {
      return 'Editar usuario';
    } else {
      return 'Nuevo usuario';
    }
  }

}
