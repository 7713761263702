import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent implements OnInit, OnDestroy {
  passwordSubscription: Subscription;
  passwordError = false;
  passwordSend = false;
  formPassword = {
    email: ''
  };
  constructor(private sessionService: SessionService, private router: Router) {}

  ngOnInit() {}
  sendPassword() {
    this.passwordSubscription = this.sessionService
      .sendPassword(this.formPassword)
      .subscribe(
        () => {
          this.passwordSend = true;
        },
        error => {
          console.log('error');
          this.passwordError = true;
        }
      );
  }
  ngOnDestroy(): void {
    if (typeof(this.passwordSubscription) !== 'undefined') {
       this.passwordSubscription.unsubscribe();
     }
   }
}
