import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionTitle'
})
export class ActionTitlePipe implements PipeTransform {

  transform(value: any): string {
    if (value === 1) {
      return 'De Autodesarrollo';
    }
    return 'Formación';
   }

}
