import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FilterValues } from "src/app/Models/dashboard-interface";

@Component({
  selector: "app-dashboard-chart-filter-box",
  templateUrl: "./dashboard-chart-filter-box.component.html",
  styleUrls: ["./dashboard-chart-filter-box.component.scss"]
})
export class DashboardChartFilterBoxComponent implements OnInit {
  @Output()
  filterValuesRequest = new EventEmitter();

  filterValues: FilterValues[];
  @Input() idCampaign: number;

  constructor() {
    this.filterValues = [new FilterValues()];
  }

  ngOnInit() {}

  addFilter() {
    this.filterValues.push(new FilterValues());
  }

  removeFilter(index) {
    this.filterValues.splice(index, 1);
  }

  resetFilter() {
    for (const filterValue of this.filterValues) {
      filterValue.reset();
    }
    this.filterValues.splice(1);
    this.emitFilterValues();
  }

  filterChange(obj, index) {}

  requestFilterValues() {
    this.emitFilterValues();
  }

  emitFilterValues() {
    this.filterValuesRequest.emit(this.filterValues);
  }
}
