import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EvaluatedCard } from '../Models/evaluated-card.interface';
import { EvaluationResponse } from '../Models/evaluator-interface';
import { ChartResult } from '../Models/result-interface';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  constructor(private http: HttpClient) {}

  getEvaluators() {
    return this.http.get<EvaluationResponse>(
      `${environment.UrlCoop}/api/v1/evaluation/card`
    );
  }

  getResults(idCampaign: number) {
    return this.http.get<ChartResult>(
      `${environment.UrlCoop}/api/v1/result/chart?c=${idCampaign}`,
      { observe: 'response' }
    );
  }

  getEvaluationCards(idCampaign: number) {
    return this.http.get<Array<EvaluatedCard>>(
      `${environment.UrlCoop}/api/v1/evaluation?c=${idCampaign}`
    );
  }

  getEvaluationGraphic(idCampaign: number) {
    return this.http.get(
      `${environment.UrlCoop}/api/v1/result/chart/allEvaluated?c=${idCampaign}`
    );
  }

  getEvaluationsPdf(evalId, questIds) {
    const data = {
      idEvaluated: evalId,
      idsEvaluation: questIds,
    };

    return this.http.post(
      `${environment.UrlCoop}/api/v1/questionnaire/printPdf`,
      data
    );
  }
  updateEvaluation(body) {

    return this.http.put(
      `${environment.UrlCoop}/api/v1/evaluation/updateEvaluation`,
      body
    );
  }

  createEvaluation(body, idCampaign) {
    return this.http.post(
      `${environment.UrlCoop}/api/v1/evaluation/createEvaluation?c=${idCampaign}`,
      body
    );
  }
}
