import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuestionService} from './services/question.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from '../token-interceptor';
import {TextQuestionComponent} from './components/text-question/text-question.component';
import {RangeQuestionComponent} from './components/range-question/range-question.component';
import {RadioQuestionComponent} from './components/radio-question/radio-question.component';
import {ActionQuestionComponent} from './components/action-question/action-question.component';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import {ScrollbarModule} from 'ngx-scrollbar';
import {QuestionaryPipe} from './pipes/questionary.pipe';
import {RoundNumberPipe} from './pipes/round-number.pipe';
import {ActionTitlePipe} from './pipes/actionTitle.pipe';
import {SelectQuestionComponent} from './components/select-question/select-question.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {SWIPER_CONFIG} from 'ngx-swiper-wrapper';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { QuestionComponent } from './components/question/question.component';
import { SummaryQuestionComponent } from './components/summary-question/summary-question.component';
import { GetInitialsPipe } from '../pipes/GetInitials.pipe';
import { ArraySortPipe } from '../pipes/array-sort.pipe';
import { SuggestQuestionComponent } from './components/suggest-question/suggest-question.component';
import {RouterModule} from '@angular/router';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ScrollbarModule,
    NgbModule.forRoot(),
    SwiperModule,
    RouterModule
  ],
  declarations: [
    TextQuestionComponent,
    RangeQuestionComponent,
    RadioQuestionComponent,
    ActionQuestionComponent,
    QuestionaryPipe,
    RoundNumberPipe,
    ActionTitlePipe,
    GetInitialsPipe,
    ArraySortPipe,
    SelectQuestionComponent,
    QuestionnaireComponent,
    QuestionComponent,
    SummaryQuestionComponent,
    SuggestQuestionComponent
  ],
  exports: [
    QuestionnaireComponent,
    GetInitialsPipe
  ],
  providers: [
    QuestionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ]
})
export class QuestionsModule {
}
