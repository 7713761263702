import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as FileSaver from "file-saver";
import * as json2csv from "json2csv";
import {
  EvaluatedKPIROW,
  FilterValues,
} from "../../../Models/dashboard-interface";
import { Participant } from "../../models/participants.model";
import { ReportCSV } from "../../models/report-csv-model";
import { DashboardService } from "../../services/dashboard.service";
import { ReportService } from "../../services/report.service";

@Component({
  selector: "app-tabla",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.scss"],
})
export class TablaComponent implements OnInit, OnChanges {
  @Input() managerMode: boolean;

  totalData: EvaluatedKPIROW[];
  reportData: any;
  csvData: ReportCSV[];
  filterValues: FilterValues[];
  reportDataLoaded = false;
  public csvFileName = `report.csv`;
  csvString: string;
  page = 1;
  pageSize = 10;
  dataSize;

  dataCSV: string;
  @Input() idCampaign: number;

  dataTable: Participant[];

  dataRef: any;
  constructor(
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.loadData(this.filterValues);
    this.loadReportData();
  }

  getCSVDownloadLink() {
    return this.reportService.generateCSVDownloadLink({
      filename: this.csvFileName,
      data: this.csvData,
      columns: ReportService.getColumnArray(),
      encoding: "utf-8",
    });
  }

  loadData(filter?: FilterValues[]) {
    const callback = (data) => {
      this.totalData = data;
      this.totalData.sort((a, b) => {
        return a.id - b.id;
      });
      let index = 21;
      this.totalData.forEach((el) => {
        el.index1 = index + 1;
        el.index2 = index + 2;
        el.index3 = index + 3;
        el.index4 = index + 4;
        el.index5 = index + 5;
        el.index6 = index + 6;
        el.index7 = index + 7;
        el.index8 = index + 8;
        index = index + 8;
      });
      this.dataSize = this.totalData.length;
    };
    const errorCallback = (error) => {
      this.totalData = [];
    };
    if (this.managerMode) {
      this.dashboardService
        .getEvaluatedKPIsManager(filter)
        .subscribe(callback, errorCallback);
    } else {
      this.dashboardService
        .getEvaluatedKPIs(filter, this.idCampaign)
        .subscribe(callback, errorCallback);
    }
  }

  loadDataEvaluated(id, tablaModal) {
    this.dashboardService.getEvaluatedById(id, this.idCampaign).subscribe((response) => {
      this.dataTable = response;
      this.dataRef = this.modalService.open(tablaModal, { centered: true });
    });
  }
  loadReportData() {
    const callback = (response) => {
      this.reportData = response;
      this.csvData = this.reportService.parseReport(this.reportData);
      this.reportDataLoaded = true;
    };
    const errorCallback = (error) => {
      this.reportDataLoaded = false;
    };
    this.reportService.retrieveReportData(this.idCampaign).subscribe(callback, errorCallback);
  }

 /*  downloadExcel() {
    const csv_type = "text/csv";

    //let csv = this.parseCSV();
    const fields = ReportService.getColumnArray();
    const opts = {
      fields,
      output: this.csvFileName,
      delimiter: ";",
    };

    const csv = json2csv.parse(this.csvData, opts);
    const data = new Blob(["\ufeff", csv], {
      type: "text/csv;charset=ISO-8859-1;",
    });
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(data, this.csvFileName);
      return;
    } else {
      const obj = window.URL.createObjectURL(data);
      FileSaver.saveAs(obj, this.csvFileName);
    }
  } */

  filterValuesRequest(data) {
    this.filterValues = data;
    this.loadData(this.filterValues);
  }

  /**
   *
   */
  parseCSV() {
    const array =
      typeof this.csvData !== "object"
        ? JSON.parse(this.csvData)
        : this.csvData;
    let str = "";
    const keys = Object.keys(array[0]);
    let strKey = "";
    for (let i = 0; i < keys.length; i++) {
      strKey += keys[i] + ";";
    }
    strKey += "\r\n";
    str = strKey;
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line != "") {
          line += ";";
        }
        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  closeModal() {
    this.dataRef.close();
  }

  downloadExcel() {
    this.dashboardService.downloadExcel(this.filterValues, this.idCampaign).subscribe(resp => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('type', 'hidden');
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        // tslint:disable-next-line: no-string-literal
        resp['payload'];
      // tslint:disable-next-line: no-string-literal
      a.download = 'download.xlsx';
      a.click();
      document.body.removeChild(a);
    })
  }
}
