import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampaingService} from '../../services/campaing.service';
import {CampaignDetalInterface, CampaignFieldsInterface, CampaignInterface, IndividualDateInterface} from '../../Models/campaign-interface';
import { User } from 'src/app/Models/user-interface';

@Component({
  selector: 'app-table-campaigns-finalized',
  templateUrl: './table-campaigns-finalized.component.html',
  styleUrls: ['./table-campaigns-finalized.component.scss']
})
export class TableCampaignsFinalizedComponent implements OnInit {
  public hiModal = false;
  public campaignList: CampaignInterface[];
  public campaignDetail: CampaignDetalInterface;
  @Input() campaignListFinalized: CampaignInterface[];
  @Input() user: User;
  
  @Output() reloadCampaign = new EventEmitter();
  public startDate: IndividualDateInterface;
  public endDate: IndividualDateInterface;
  public campaignFields: CampaignFieldsInterface;
  public idCampaignSelected: number;
  public editMode: boolean;
  public listStatus = [];
  constructor(private campaignService: CampaingService) {}
  ngOnInit() {
    this.initVar();
    this.getCampaign();
    
    this.getStatus();
  }
  public initVar() {
    this.startDate = {
      year: null,
      month: null,
      day: null
    };
    this.endDate = {
      year: null,
      month: null,
      day: null
    };

    this.campaignFields = {
      name: false,
      startDate: false,
      endDate: false
    };
    this.editMode = false;
    
    this.campaignDetail = {
      description: '',
      endDate: null,
      loadState: '',
      stage: '',
      startDate: null,
      status: '',
      name: ''
    };
  }
  public hideModal() {
    this.editMode = false;
    this.hiModal = !this.hiModal;
    this.idCampaignSelected = null;
  }
  getCampaign() {
    this.campaignService.getCampaigns().subscribe(resp => {
      this.campaignList = resp;
    });
  }



  parseDateToNgbDatepicker(field: string, d1: Date) {
    this[field].year = d1.getFullYear();
    this[field].month = d1.getMonth() + 1;
    this[field].day = d1.getDate();
  }


  getTranslateStatus(status: string) {
    switch (status) {
      case 'INICIO':
        return 'En progreso';
      case 'VIGENTE':
        return 'Iniciada';
      case 'FINALIZADA':
        return 'Finalizada';
    }
  }

  getTitleModal() {
    if (this.editMode) {
      return 'Editar campaña';
    } else {
      return 'Nueva campaña';
    }
  }

  getBtnModal() {
    if (this.editMode) {
      return 'Editar';
    } else {
      return 'Crear';
    }
  }

  getCampaignById(id: number) {
    this.campaignService.getCampaignById(id).subscribe(resp => {
      this.editMode = true;
      this.startDate = {
        year: Number(resp.startDate.split('-')[0]),
        month: Number(resp.startDate.split('-')[1]),
        day: Number(resp.startDate.split('-')[2])
      };
      this.endDate = {
        year: Number(resp.endDate.split('-')[0]),
        month: Number(resp.endDate.split('-')[1]),
        day: Number(resp.endDate.split('-')[2])
      };
      this.campaignDetail = resp;
      this.hideModal();
    });
  }
  getStatus() {
    this.campaignService.getStatus().subscribe(resp => {
      this.listStatus = resp;
    });
  }

  postCampaign() {
    if (this.startDate.year != null) {
      this.campaignDetail.startDate = this.formatDate(this.startDate);
    }
    if (this.endDate.year != null) {
      this.campaignDetail.endDate = this.formatDate(this.endDate);
    }
    if (this.validData() === 0) {
      
      this.campaignService.editCampaign(this.campaignDetail, this.campaignDetail.id).subscribe(resp => {
        //this.getCampaign();
        this.hideModal();
        this.initVar();
        this.reloadCampaign.emit(true);
      });
    }
  }

  validData() {
    let errors = 0;
    if (this.campaignDetail.name === '' || this.campaignDetail.name === null) {
      this.campaignFields.name = true;
      errors++;
    } else {
      this.campaignFields.name = false;
    }
    if (this.campaignDetail.startDate === null) {
      this.campaignFields.startDate = true;
      errors++;
    } else {
      this.campaignFields.startDate = false;
    }
    if (this.campaignDetail.endDate === null) {
      this.campaignFields.endDate = true;
      errors++;
    } else {
      this.campaignFields.endDate = false;
    }
    return errors;
  }
  formatDate(d1: IndividualDateInterface) {
    const year = d1.year.toString();
    let month = d1.month.toString();
    let day = d1.day.toString();
    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }

    return year + '/' + month + '/' + day;
  }
}
