export interface DataResponse {
  general: General[];
  funtion: Funtion[];
}
export interface General {
  name: string;
  value: number;
}
export interface Funtion {
  funtionName: string;
  valuesFuntion: Funtions[];
}
export interface Funtions {
  valueName: string;
  value: number;
}

export class FilterValues {
  id1: any;
  id2: any[];

  obj1: any;
  obj2: any[];

  constructor() {
    this.reset();
  }

  reset() {
    this.id1 = undefined;
    this.id2 = [];

    this.obj1 = undefined;
    this.obj2 = [];
  }
}


export interface EvaluatedKPIROW {
  id: number;
  name: string;
  lastName: string;
  fullName: string;
  departmentName: string;
  positionName: string;
  workplaceName: string;
  jobName: string;
  enterprise?: any;
  email: string;
  autoEvaluationDone: boolean;
  grades: any[];
  ndoneEvaluations: number;
  ntotalEvaluations: number;
  index1: number;
  index2: number;
  index3: number;
  index4: number;
  index5: number;
  index6: number;
  index7: number;
  index8: number;
  meanAutoEvaluations: number;
  meanEvaluations: number;
}

