import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-horizontal-bar-mean",
  templateUrl: "./horizontal-bar-mean.component.html",
  styleUrls: ["./horizontal-bar-mean.component.scss"],
})
export class HorizontalBarMeanComponent implements OnInit {
  @Input() colors = ["#73D9B2", "#70C2BA", "#73C7D9"];

  @Input() data: any;

  results: any[] = [];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  legendPosition: string = "below";
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;

  colorScheme = {
    domain: null,
  };

  schemeType: string = "linear";

  constructor() {}

  ngOnInit() {
    this.colorScheme.domain = this.colors;
    this.generateParseDataToChart();
  }

  generateParseDataToChart() {
    this.data.forEach((e) => {
      this.results.push({
        name: e.text,
        value: e.value,
      });
    });
  }
}
