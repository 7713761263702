import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
})
export class LegendComponent implements OnInit {
  @Input() values;

  constructor() {}

  ngOnInit() {
    this.values.sort((a, b) => b.order - a.order);
  }
}
