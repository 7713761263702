import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {EmployeeAllInterface} from '../Models/employee-interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(private http: HttpClient) {}

  getAllEmployees() {
    return this.http.get<EmployeeAllInterface[]>(
      `${environment.UrlCoop}/api/v1/user/allUsers`
    );
  }

  createEmployee(body) {
    return this.http.post<any>(
      `${environment.UrlCoop}/api/v1/user/create`, body
    );
  }
  editEmployee(body) {
    return this.http.put<any>(
      `${environment.UrlCoop}/api/v1/user/update`, body
    );
  }

  deleteEmployee(idCampaign: number, idUser: number) {
    return this.http.delete<any>(
      `${environment.UrlCoop}/api/v1/campaign/${idCampaign}/${idUser}`
    );
  }
}
