import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FilterValues } from 'src/app/Models/dashboard-interface';
import { FormsModule } from '@angular/forms';
import {DashboardService} from '../../services/dashboard.service';
import {FilterSelect} from '../../../Models/select-interface';
import {forEach} from '@angular/router/src/utils/collection';
import {convertRuleOptions} from 'tslint/lib/configuration';

@Component({
  selector: 'app-dashboard-chart-filter',
  templateUrl: './dashboard-chart-filter.component.html',
  styleUrls: ['./dashboard-chart-filter.component.scss']
})
export class DashboardChartFilterComponent implements OnInit {

  @Input()
  selectedValues: FilterValues;

  @Output()
  changeSelected = new EventEmitter();

  values1: any[];
  values2: any[];
  selected2: any[];
  dropdownSettings2;
  @Input() idCampaign: number;
  constructor(private dashboardService: DashboardService) {
    this.resetValues();

    this.dropdownSettings2 = {
      singleSelection: false,
      placeholder: 'Seleccionar...',
      idField: 'id',
      textField: 'text',
      enableCheckAll: false
    };
   }

  ngOnInit() {
    this.loadData();
    this.selectedValues.id1 = '';
  }

  loadData( ) {
    const callback = data => {
      const filters: FilterSelect[] = data;
      const valuesA = [];
      filters.forEach(value => {
        valuesA.push({
          id: value.slug,
          text: value.title,
          options: value.options
        });
      });
      this.values1 = valuesA;
    };
    this.dashboardService.getSelectsForKPIs(this.idCampaign).subscribe(callback);
  }

  emitSelected() {
    if (this.selected2) {
      this.selectedValues.id2 = this.selected2.map(item => item.id);
      this.selectedValues.obj2 = this.selected2.map(item => this.values2.filter(v => v.id === item.id)[0]);
    } else {
      this.selectedValues.id2 = [];
      this.selectedValues.obj2 = [];
    }
    this.changeSelected.emit(this.selectedValues);
  }

  resetValues(obj?) {
    this.values1 = [];
  }

  getValues2(obj?) {
    const response = [];
    obj.options.forEach(option => {
      response.push({
        id: option.id,
        text: option.name
      });
    });
    this.values2 = response;
  }

  selectChange1(id) {
    const value = this.values1.filter(v => v.id === id)[0];
    this.selectedValues.obj1 = value;

    if (!value) {
      this.values2 = [];
    } else {
      this.getValues2(value);
    }

    this.selected2 = [];

    this.emitSelected();
  }

}
