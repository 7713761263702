import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reply-token',
  templateUrl: './reply-token.component.html',
  styleUrls: ['./reply-token.component.scss']
})
export class ReplyTokenComponent implements OnInit {
 
  constructor(private route: ActivatedRoute, private router:Router) { }

  ngOnInit() {

      if ( window.location.hash !== '' && window.location.hash.indexOf('id_token') !== -1) {
        const token = window.location.hash.split('id_token=')[1].split('&')[0];
        const myToken = {
            token: token,
            refresh : ''
        };
        localStorage.setItem('token', JSON.stringify(myToken));
        this.router.navigate(['']);
      }
    
  }

}
