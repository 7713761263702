import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getInitials'
})

export class GetInitialsPipe implements PipeTransform {
  transform(value: string) {
    let splitted = value.split(" ");
    let result = ""
    let count = 0;
    if(splitted.length > 0){
      result += splitted[0].charAt(0);
    }
    if(splitted.length > 1){
      result += splitted[1].charAt(0);
    }
    return result;
  }
}
