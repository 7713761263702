import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Category, Questionnaire } from "../../models/questionnaire-interface";

@Component({
  selector: "app-summary-question",
  templateUrl: "./summary-question.component.html",
  styleUrls: ["./summary-question.component.scss"],
  animations: [
    trigger("showComment", [
      state(
        "hide",
        style({
          opacity: "0",
          display: "none"
        })
      ),
      state(
        "show",
        style({
          opacity: "1"
        })
      ),
      transition("hide <=> show", animate("300ms ease-in"))
    ])
  ]
})
export class SummaryQuestionComponent implements OnInit {
  @ViewChild("NgScrollbar") scrollbarRef;
  @Input() questionnaire: Questionnaire;
  @Output() editQuestionEmitter: EventEmitter<any>;

  showState = "show";

  constructor() {
    this.editQuestionEmitter = new EventEmitter();
  }

  ngOnInit() {
    console.log(this.questionnaire);
  }

/*   openCommentArea() {
    this.showState = this.showState === "show" ? "hide" : "show";
    if (this.showState === "show") {
      this.scrollbarRef.scrollToBottom(300);
    }
  } */

  editQuestion(category: number, question: number) {
    const questionPosition = {
      question: question,
      subcategory: null,
      category: category
    };
    this.editQuestionEmitter.emit(questionPosition);
  }

  editQuestionGroup(category: number, subcategory: number) {
    const questionPosition = {
      question: null,
      subcategory: subcategory,
      category: category
    };
    this.editQuestionEmitter.emit(questionPosition);
  }

  calculateCategoryMean(category: Category) {
    if (this.questionnaire.groupSubcategories) {
      let count = 0;
      let sum = 0;
      category.subcategories.forEach(e => {
        e.questions.forEach((value, index) => {
          sum += value.answer && value.answer.value ? value.answer.value : 0;
          count++;
        });
      });
      if (count !== 0) {
        return ((sum / count) / 6 ) * 100;
      }
      return 0;
    } else {
      const count = category.questions.length;
      let sum = 0;
      category.questions.forEach((value, index) => {
        sum += value.answer.value != null ? value.answer.value : 0;
      });
      if (count !== 0) {
        return ((sum / count) / 6 ) * 100;;
      }
      return 0;
    }
  }
}
