import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { EvaluatedCard } from '../../Models/evaluated-card.interface';
import { ChartResult } from '../../Models/result-interface';
import { ResultResponse } from '../../Models/results-response-interface';
import { User } from '../../Models/user-interface';
import { EvaluationService } from '../../services/evaluation.service';
import { HomeService } from '../../services/home.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('showGraphs', [
      state(
        'hide',
        style({
          opacity: '0',
          display: 'none',
        })
      ),
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      transition('hide <=> show', animate('300ms ease-in')),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Output() resultsChange: EventEmitter<boolean>;

  @Input() showCard: string;

  hasResults = false;
  userSubscription: Subscription;
  evaluationCardsSubscription: Subscription;
  user: User;
  evaluationCards: Array<EvaluatedCard>;
  results: ChartResult[];
  maxAndMinValues;
  activeResult: ResultResponse;
  fourColumn: Boolean;
  visibleSol: Boolean;
  visibleRes: Boolean;
  visibleData: Boolean;
  visibleEvaluations: Boolean;
  graph: Boolean;
  hideGraphs: string;
  cardVisible: string;
  mobile: boolean;
  state = 'hide';
  viewHome: boolean;
  viewDashboard: boolean;
  viewTabla: boolean;
  viewMatrix: boolean;
  viewResults: boolean;
  viewEvaluations: boolean;
  managerMode: boolean;
  itemActive: string;
  showIntro: boolean;
  indexResults: number;
  nextIndex: number;

  evaluationsResults;

  ClickCounter: number;
  idCampaign: number;
  constructor(
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private evaluationService: EvaluationService,
    private homeService: HomeService,
    private activateRouter: ActivatedRoute
  ) {
    this.resultsChange = new EventEmitter<boolean>();
    this.visibleEvaluations = false;
    this.managerMode = false;
    this.cardVisible = this.homeService.returnVisibleCard()
      ? this.homeService.returnVisibleCard()
      : 'datos';

    homeService.getVisibleCard().subscribe((data) => {
      this.cardVisible = data;
    });

    this.activateRouter.params.subscribe(params => {
      this.idCampaign = params['id'];
    });
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.mobile = window.innerWidth < 768;
  }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if (this.graph && e.keyCode === 27) {
      this.closeModal();
    }
  }

  animate() {
    this.state = this.state === 'show' ? 'hide' : 'show';
  }

  closeModal() {
    this.hideGraphs = '';
    this.state = this.state === 'show' ? 'hide' : 'show';
  }

  ngOnInit() {
    this.showIntro = false;
    this.itemActive = '';
    this.visibleData = true;
    this.visibleSol = false;
    this.visibleRes = false;
    this.fourColumn = false;
    this.graph = true;
    this.myHome();
    this.getMyEvaluations();
    this.getEvaluatedGraphic();
    this.loadResults();
    this.viewMatrix = false;
    this.viewDashboard = false;
    this.viewHome = true;
    if (window.innerWidth < 768) {
      this.mobile = true;
    }
    switch (this.router.url.split('/')[1]) {
      case 'general':
        this.homeView();
        break;
      case 'dashboard':
        this.dashboardView();
        break;
      case 'tabla':
        this.tablaView();
        break;
      case 'matriz':
        this.matrixView();
        break;
      case 'resultados':
        this.resultsView();
        break;
      case 'evaluaciones':
        this.evaluationsView();
        break;
      default:
        this.homeView();
        break;
    }

    this.indexResults = 19;
    this.nextIndex = 19;
  }

  navigateURL(url) {
    if (url !== this.router.url) {
      this.router.navigate([url]);
    }
  }

  introSuccess() {
    this.showIntro = false;
    localStorage.setItem('showIntro', 'x');
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  }

  countChangedHandler(count: number) {
    this.nextIndex = count;
  }

  myHome() {
    this.userSubscription = this.userService.getUser(this.idCampaign).subscribe((user) => {
      this.user = user;
      this.showIntro =
        this.user.firstTimeLogin || this.user.firstTimeLogin === undefined
          ? true
          : false;
      // si el usuario es manager pero no admin o director, está en modo manager directamente
      if (this.user.id === 3 || this.user.id === 4 || this.user.id === 6) {
        this.user.manager = true;
      }
      this.user.gender = 'F';
      this.managerMode = this.user.roleName === 'SIMPLE' && this.user.manager;
      if (this.user && !localStorage.getItem('currentUser')) {
        this.sessionService.saveUser(user);
      }
    });
  }

  getEvaluatedGraphic() {
    this.evaluationService.getEvaluationGraphic(this.idCampaign).subscribe((d) => {
      this.evaluationsResults = d ? d : [];
    });
  }

  getMyEvaluations() {
    this.evaluationCardsSubscription = this.evaluationService
      .getEvaluationCards(this.idCampaign)
      .subscribe((evaluationCards) => {
        this.evaluationCards = evaluationCards;
        if (evaluationCards.length > 0) {
          this.evaluationCards.forEach((value) => {
            value.index = this.indexResults + 1;
            this.indexResults++;
            value.index2 = this.indexResults + 1;
            this.indexResults++;
            value.questionnaires.sort((a, b) => {
              return a.questionnaireGroupId - b.questionnaireGroupId;
            });
            let ind = 0;
            value.questionnaires.forEach((el) => {
              el.index = this.indexResults + ind + 1;
              ind++;
            });
            this.indexResults += ind + 1;
          });
          this.visibleEvaluations = true;
        } else {
          // this.resultsView();
        }
        this.evaluationCards.forEach((value) => {
          this.indexResults += 2;
          this.indexResults += value.questionnaires.length;
        });
        this.indexResults++;
      });
  }

  moreColumn() {
    this.fourColumn = this.fourColumn === false;
  }

  logout() {
    this.sessionService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.evaluationCardsSubscription.unsubscribe();
  }

  onResultsChange(event): void {
    this.hasResults = event;
  }

  loadResults() {
    this.evaluationService.getResults(this.idCampaign).subscribe(
      (response) => {
        if (response && response.body[0]) {
          // order
          this.results = response.body[0].categories.sort((a, b) => {
            return a.id - b.id;
          });
          this.maxAndMinValues = response.body[0].groupGradeSetGrades;
          this.hasResults = true;
        }
      },
      () => {
        this.hasResults = false;
      }
    );
  }

  hideAll() {
    this.viewHome = false;
    this.viewDashboard = false;
    this.viewTabla = false;
    this.viewMatrix = false;
    this.viewResults = false;
    this.viewEvaluations = false;
  }

  homeView() {
    this.itemActive = 'General';
    this.hideAll();
    this.viewHome = true;
  }

  dashboardView() {
    this.itemActive = 'Dashboard';
    this.hideAll();
    this.viewDashboard = true;
  }

  tablaView() {
    this.itemActive = 'Tabla';
    this.hideAll();
    this.viewTabla = true;
  }

  matrixView() {
    this.itemActive = 'Matriz';
    this.hideAll();
    this.viewMatrix = true;
  }

  resultsView() {
    this.itemActive = 'Resultados';
    this.hideAll();
    this.viewResults = true;
  }

  evaluationsView() {
    this.itemActive = 'Evaluaciones';
    this.hideAll();
    this.viewEvaluations = true;
  }

  onKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Spacebar') {
    }
  }
}
