import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyInterface, QuestionnarieInterface} from '../../Models/property-interface';
import {PropertyService} from '../../services/property.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  public isCollapsedDepartments = true;
  public isCollapseFunctions = true;
  public isCollapseJobs = true;
  public isCollapseCenters = true;
  public isCollapseQuestionnaire = true;
  public pageDepartment = 1;
  public pageFunction = 1;
  public pageJob = 1;
  public  pageWorkCenter = 1;
  public  pageQuestionnaire = 1;
  public totalPerPage = 5;
  @Input() propertyModel: PropertyInterface;
  @Input() questionnaireList: QuestionnarieInterface[];
  @Output() reloadAdministration = new EventEmitter();
  public hideModalDepartments: boolean;
  public hideModalFunctions: boolean;
  public hideModalJobs: boolean;
  public hideModalCenters: boolean;

  public newDepartment: string;
  public newFunction: string;
  public newJob: string;
  public newCenter: string;
  public emptynewDepartment: boolean;
  public emptynewFunction: boolean;
  public emptynewJob: boolean;
  public emptynewCenter: boolean;
  public editModal: boolean;
  public idModal: number;
  public showError: boolean;
  public txtError: string;
  constructor(private propertyService: PropertyService) { }

  ngOnInit() {
    this.initVar();
  }

  public initVar() {
    this.newDepartment = '';
    this.newFunction = '';
    this.newJob = '';
    this.newCenter = '';
    this.hideModalDepartments = false;
    this.hideModalFunctions = false;
    this.hideModalJobs = false;
    this.hideModalCenters = false;
    this.emptynewDepartment = false;
    this.emptynewFunction = false;
    this.emptynewJob = false;
    this.emptynewCenter = false;
    this.editModal = false;
    this.showError = false;
    this.txtError = '';

  }


  public hideModal(field, event = null, valueProperty: string = null, fieldProperty: string = null, fieldId: number = null) {
    this[field] = !this[field];
    if ( valueProperty != null) {
      this[fieldProperty] = valueProperty;
      this.editModal = true;
      this.idModal = fieldId;
    } else {
      this.idModal = 0;
      this.editModal = false;
    }
    if (event !== null) {
      event.stopPropagation();
    }
  }

  validData(field: string) {
    let errors = 0;
    switch (field) {
      case 'newDepartment':
        if (this.newDepartment === '') {
          errors++;
          this.emptynewDepartment = true;
        } else {
          this.emptynewDepartment = false;
        }
        break;
      case 'newFunction':
        if (this.newFunction === '') {
          errors++;
          this.emptynewFunction = true;
        } else {
          this.emptynewFunction = false;
        }
        break;
      case 'newJob':
        if (this.newJob === '') {
          errors++;
          this.emptynewJob = true;
        } else {
          this.emptynewJob = false;
        }
        break;
      case 'newCenter':
        if (this.newCenter === '') {
          errors++;
          this.emptynewCenter = true;
        } else {
          this.emptynewCenter = false;
        }
        break;
    }
    return errors;
  }

  createDepartment() {
    if (this.validData('newDepartment') === 0) {
      const obj = {
        'id': this.idModal,
        'name': this.newDepartment
      };
      if (!this.editModal) {
        this.propertyService.createProperty(obj, 'department').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalDepartments');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un departamento con ese nombre';
        });
      } else {
        this.propertyService.modifyProperty(obj, 'department').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalDepartments');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un departamento con ese nombre';
        });
      }
    }
  }

  createFunction() {
    if (this.validData('newFunction') === 0) {
      const obj = {
        'id': this.idModal,
        'name': this.newFunction
      };
      if (!this.editModal) {
        this.propertyService.createProperty(obj, 'position').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalFunctions');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe una función con ese nombre';
        });
      } else {
        this.propertyService.modifyProperty(obj, 'position').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalFunctions');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe una función con ese nombre';
        });
      }
    }
  }

  createJob() {
    if (this.validData('newJob') === 0) {
      const obj = {
        'id': this.idModal,
        'name': this.newJob
      };
      if (!this.editModal) {
        this.propertyService.createProperty(obj, 'job').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalJobs');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un puesto con ese nombre';
        });
      } else {
        this.propertyService.modifyProperty(obj, 'job').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalJobs');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un puesto con ese nombre';
        });
      }
    }
  }

  createCenter() {
    if (this.validData('newCenter') === 0) {
      const obj = {
        'id': this.idModal,
        'name': this.newCenter
      };
      if (!this.editModal) {
        this.propertyService.createProperty(obj, 'workplace').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalCenters');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un centro con ese nombre';
        });
      } else {
        this.propertyService.modifyProperty(obj, 'workplace').subscribe(resp => {
          console.log(resp);
          this.hideModal('hideModalCenters');
          this.reloadAdministration.emit(true);
        }, err => {
          this.showError = true;
          this.txtError = 'Ya existe un centro con ese nombre';
        });
      }
    }
  }

  getTitleModal() {
    if ( this.editModal ) {
      return 'Editar';
    } else {
      return 'Crear';
    }
  }
  getBtnModal() {
    if ( this.editModal ) {
      return 'Editar';
    } else {
      return 'Crear';
    }
  }
}
