import { Routes } from '@angular/router';
import { CampaingsComponent } from './campaings/campaings.component';
import { HomeComponent } from './components/home/home.component';
import { LogInComponent } from './components/log-in/log-in.component';
import { MainComponent } from './components/main/main.component';
import { RememberPasswordComponent } from './components/remember-password/remember-password.component';
import { ReplyTokenComponent } from './components/reply-token/reply-token.component';
import { AuthGuardsService } from './guards/auth-guards.service';
import { QuestionnaireComponent } from './questions/components/questionnaire/questionnaire.component';
import { CampaingsDetailComponent } from './campaings-detail/campaings-detail.component';
import {EmployeesComponent} from './employees/employees.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardsService],
    children: [
      { path: '', component: CampaingsComponent, canActivate: [AuthGuardsService] },
      {
        path: 'general/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'dashboard/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'tabla/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'matriz/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'resultados/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'evaluaciones/:id',
        component: HomeComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'campaings',
        component: CampaingsComponent,
        canActivate: [AuthGuardsService],
      },

      {
        path: 'employees/:id',
        component: EmployeesComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'campaings-detail/:id',
        component: CampaingsDetailComponent,
        canActivate: [AuthGuardsService],
      },
      {
        path: 'questions/:id/:idCampaign',
        component: QuestionnaireComponent,
        canActivate: [AuthGuardsService],
      },
    ],
  },
  {
    path: 'reply-token',
    component: ReplyTokenComponent,
  },
  {
    path: 'login',
    component: LogInComponent,
    canActivate: [AuthGuardsService],
  },
  {
    path: 'login/rempass',
    component: RememberPasswordComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
