import {AfterViewChecked, Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';


import {MatrixService} from '../../services/matrix.service';

import {SessionService} from '../../../services/session.service';
import {MatrixUserModel} from '../../models/matrix-user-model';
import {MatrixCommentModel} from '../../models/matrix-comment-model';
import {KonvaComponent} from 'ng2-konva';

declare const konva: any;

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(360deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
    ])
  ]
})

export class MatrixComponent implements OnInit {
  @ViewChild('layer') layer: KonvaComponent;
  @ViewChildren('text') components: QueryList<KonvaComponent>;

  public textWidth = 100;
  public xAxisOffset = this.textWidth + 10;
  public matrixTextOffset = this.textWidth + 20;
  public width = 800;
  public height = 400;
  public distance = 100;
  public visibleComment = false;
  public rects: Array<any> = [];

  matrixSubscription: Subscription;
  public dataMatrix: any;
  public userMatrix: Array<MatrixUserModel>;
  public commentMatrix: Array<MatrixCommentModel>;

  public visibleMatrix = true;
  flip = 'inactive';
  @Input() idCampaign: number;

  constructor(private matrixService: MatrixService) {
    this.dataMatrix = {
      data: [],
      axis: {
        'y': '',
        'x': ''
      }
    };
  }

  ngOnInit() {
  }




  toggleFlip(index) {
    console.log('flip ' + index);
    this.flip = (this.flip === 'inactive') ? 'active' : 'inactive';
    this.visibleMatrix = !this.visibleMatrix;
    if (this.dataMatrix != null) {
      this.visibleComment = false;
      this.commentMatrix = [];
      if (index != null) {
        this.matrixSubscription = this.matrixService.getMatrixId(index).subscribe(
          response => {
            if (response) {
              this.userMatrix = response;
            }
          }
        );
      }
    }
  }

  showComments(userId) {
    if (this.visibleComment) {
      this.commentMatrix = [];
      this.visibleComment = !this.visibleComment;
    } else {
      this.matrixSubscription = this.matrixService.getMatrixComment(userId).subscribe(
        response => {
          if (response) {
            this.commentMatrix = response;
            this.visibleComment = !this.visibleComment;
          }
        }
      );
    }
  }
}

