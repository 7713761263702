import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SessionService } from "./services/session.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(private sessionService: SessionService) {}

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: "Bearer " + token } });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.sessionService.getToken();
    const authHeader = request.headers.get("Authorization");
    if (token) {
      request = this.addToken(request, token);
    }
    if (token) {
      if (
        !authHeader ||
        (!authHeader.startsWith("Bearer") && !authHeader.startsWith("Basic"))
      ) {
        request = this.addToken(request, token);
      }
    }

    return next.handle(request).pipe(
      catchError((error) => {
        const responseCode = error.status;
        if (error instanceof HttpErrorResponse && responseCode !== 200) {
          console.log("error!!!!!!!!!");
          console.log(error);
          if (responseCode !== 404 && responseCode !== 500) {
            this.sessionService.removeToken();
          }
        }
        return throwError(null);
      })
    );
  }
}
