import { Component, OnInit, Input } from '@angular/core';
import { NumberKPI } from '../../models/kpi-model';

@Component({
  selector: 'app-dashboard-chart-number',
  templateUrl: './dashboard-chart-number.component.html',
  styleUrls: ['./dashboard-chart-number.component.scss']
})
export class DashboardChartNumberComponent implements OnInit {
  @Input() data: NumberKPI;
  @Input() index: number;
  constructor() { }

  ngOnInit() {
  }

}
