import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Question } from "../../models/questionnaire-interface";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"]
})
export class QuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() categoryNum: number;
  @Input() questionNum: number;
  @Input() status: string;
  @Input() groupName: string;
  @Output() isValid: EventEmitter<any>;

  constructor() {
    this.isValid = new EventEmitter();
  }

  ngOnInit() {
    if (this.question.answer == null) {
      this.question.answer = {
        questionId: this.question.id,
        value: null,
        multiValue: null,
        textValue: null,
        dateValue: null,
        questionOptionId: null,
        competence: null,
        objective: null
      };
    }
  }

  isValidEmitter(isValid) {
    this.isValid.emit(isValid);
  }
}
