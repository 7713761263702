import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'commentActio'})
export class QuestionaryPipe implements PipeTransform {
  /**
   * @param value A value of any type to convert into a JSON-format string.
   */
  transform(value: any): string {
    const obj = JSON.parse(value);
    if (obj.name === '') {
      return 'Anónimo' + ' - ' + obj.comment;
    }
    return obj.name + ' - ' + obj.comment;
   }
}
