import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionSetOption, Question} from '../../models/questionnaire-interface';

@Component({
  selector: 'app-select-question',
  templateUrl: './select-question.component.html',
  styleUrls: ['./select-question.component.scss']
})
export class SelectQuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() categoryNum: number;
  @Input() questionNum: number;
  @Output() isValid: EventEmitter<any>;

  selectedButton: number = null;

  constructor() {
    this.isValid = new EventEmitter();
  }

  ngOnInit() {
    this.selectedButton = this.question.answer.questionOptionId ? this.question.answer.questionOptionId : null;
  }

  updateValue(value: number, optionId: number): void {
    this.selectedButton = optionId;
    this.question.answer.value = value;
    this.question.answer.questionOptionId = optionId;
    this.isValid.emit(this.validateQuestion());
  }

  validateQuestion() {
    return this.selectedButton !== null;
  }

  onKeydown(event, i: number, value: number, optionId: number) {
    if (event.key === 'Enter' || event.key === ' ') {

      this.updateValue(value, optionId);
    }
  }
  textOfNote(grade: number, options: OptionSetOption[]) {
    let text = '';
    options.sort((a, b) => {
      return a.order - b.order;
    });
    options.forEach((value, index) => {
      if (value.value === grade) {
        text = value.text;
      }
    });
    return text;
  }
}
