import {Injectable} from '@angular/core';
import * as json2csv from 'json2csv';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ReportCSV} from '../models/report-csv-model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
  }

  public static getColumnArray() {
    return [
      {
        label: 'Id evaluado',
        value: 'evaluatedId'
      },
      {
        label: 'Nombre y apellidos evaluado',
        value: 'evaluatedFullName'
      },
      {
        label: 'Departamento evaluado',
        value: 'evaluatedDepartmentName'
      },
      {
        label: 'Posicion evaluado',
        value: 'evaluatedPositionName'
      },
      {
        label: 'Centro trabajo evaluado',
        value: 'evaluatedWorkPlaceName'
      },
      {
        label: 'Función evaluado',
        value: 'evaluatedJobName'
      },
      {
        label: 'Id evaluador',
        value: 'evaluatorId'
      },
      {
        label: 'Nombre y apellidos evaluador',
        value: 'evaluatorFullName'
      },
      {
        label: 'Media de los 2 cuestionarios',
        value: 'totalMean'
      },
      {
        label: 'Media Competencias',
        value: (row, field) => row[field.label] !== null && row[field.label] !== undefined
         ? (row[field.label] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Viviendo los valores CPS',
        value: (row, field) => row['Competencias - Viviendo los valores CPS'] !== null && row['Competencias - Viviendo los valores CPS'] !== undefined
         ? (row['Competencias - Viviendo los valores CPS'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 1',
        value: (row, field) => row['Competencias - Pregunta 1'] !== null && row['Competencias - Pregunta 1'] !== undefined
         ? (row['Competencias - Pregunta 1'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 2',
        value: (row, field) => row['Competencias - Pregunta 2'] !== null && row['Competencias - Pregunta 2'] !== undefined
         ? (row['Competencias - Pregunta 2'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 3',
        value: (row, field) => row['Competencias - Pregunta 3'] !== null && row['Competencias - Pregunta 3'] !== undefined
         ? (row['Competencias - Pregunta 3'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 4',
        value: (row, field) => row['Competencias - Pregunta 4'] !== null && row['Competencias - Pregunta 4'] !== undefined
         ? (row['Competencias - Pregunta 4'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 5',
        value: (row, field) => row['Competencias - Pregunta 5'] !== null && row['Competencias - Pregunta 5'] !== undefined
         ? (row['Competencias - Pregunta 5'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Inspirando para movilizar',
        value: (row, field) => row['Competencias - Inspirando para movilizar'] !== null && row['Competencias - Inspirando para movilizar'] !== undefined
         ? (row['Competencias - Inspirando para movilizar'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 6',
        value: (row, field) => row['Competencias - Pregunta 6'] !== null && row['Competencias - Pregunta 6'] !== undefined
         ? (row['Competencias - Pregunta 6'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 7',
        value: (row, field) => row['Competencias - Pregunta 7'] !== null && row['Competencias - Pregunta 7'] !== undefined
         ? (row['Competencias - Pregunta 7'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 8',
        value: (row, field) => row['Competencias - Pregunta 8'] !== null && row['Competencias - Pregunta 8'] !== undefined
         ? (row['Competencias - Pregunta 8'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 9',
        value: (row, field) => row['Competencias - Pregunta 9'] !== null && row['Competencias - Pregunta 9'] !== undefined
         ? (row['Competencias - Pregunta 9'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 10',
        value: (row, field) => row['Competencias - Pregunta 10'] !== null && row['Competencias - Pregunta 10'] !== undefined
         ? (row['Competencias - Pregunta 10'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Coordinando esfuerzos',
        value: (row, field) => row['Competencias - Coordinando esfuerzos'] !== null && row['Competencias - Coordinando esfuerzos'] !== undefined
         ? (row['Competencias - Coordinando esfuerzos'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 11',
        value: (row, field) => row['Competencias - Pregunta 11'] !== null && row['Competencias - Pregunta 11'] !== undefined
         ? (row['Competencias - Pregunta 11'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 12',
        value: (row, field) => row['Competencias - Pregunta 12'] !== null && row['Competencias - Pregunta 12'] !== undefined
         ? (row['Competencias - Pregunta 12'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 13',
        value: (row, field) => row['Competencias - Pregunta 13'] !== null && row['Competencias - Pregunta 13'] !== undefined
         ? (row['Competencias - Pregunta 13'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 14',
        value: (row, field) => row['Competencias - Pregunta 14'] !== null && row['Competencias - Pregunta 14'] !== undefined
         ? (row['Competencias - Pregunta 14'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 15',
        value: (row, field) => row['Competencias - Pregunta 15'] !== null && row['Competencias - Pregunta 15'] !== undefined
         ? (row['Competencias - Pregunta 15'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Transmitiendo con impacto',
        value: (row, field) => row['Competencias - Transmitiendo con impacto'] !== null && row['Competencias - Transmitiendo con impacto'] !== undefined
         ? (row['Competencias - Transmitiendo con impacto'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 16',
        value: (row, field) => row['Competencias - Pregunta 16'] !== null && row['Competencias - Pregunta 16'] !== undefined
         ? (row['Competencias - Pregunta 16'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 17',
        value: (row, field) => row['Competencias - Pregunta 17'] !== null && row['Competencias - Pregunta 17'] !== undefined
         ? (row['Competencias - Pregunta 17'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 18',
        value: (row, field) => row['Competencias - Pregunta 18'] !== null && row['Competencias - Pregunta 18'] !== undefined
         ? (row['Competencias - Pregunta 18'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 19',
        value: (row, field) => row['Competencias - Pregunta 19'] !== null && row['Competencias - Pregunta 19'] !== undefined
         ? (row['Competencias - Pregunta 19'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 20',
        value: (row, field) => row['Competencias - Pregunta 20'] !== null && row['Competencias - Pregunta 20'] !== undefined
         ? (row['Competencias - Pregunta 20'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Buscando el cambio',
        value: (row, field) => row['Competencias - Buscando el cambio'] !== null && row['Competencias - Buscando el cambio'] !== undefined
         ? (row['Competencias - Buscando el cambio'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 21',
        value: (row, field) => row['Competencias - Pregunta 21'] !== null && row['Competencias - Pregunta 21'] !== undefined
         ? (row['Competencias - Pregunta 21'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 22',
        value: (row, field) => row['Competencias - Pregunta 22'] !== null && row['Competencias - Pregunta 22'] !== undefined
         ? (row['Competencias - Pregunta 22'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 23',
        value: (row, field) => row['Competencias - Pregunta 23'] !== null && row['Competencias - Pregunta 23'] !== undefined
         ? (row['Competencias - Pregunta 23'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 24',
        value: (row, field) => row['Competencias - Pregunta 24'] !== null && row['Competencias - Pregunta 24'] !== undefined
         ? (row['Competencias - Pregunta 24'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 25',
        value: (row, field) => row['Competencias - Pregunta 25'] !== null && row['Competencias - Pregunta 25'] !== undefined
         ? (row['Competencias - Pregunta 25'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Mostrando profesionalidad',
        value: (row, field) => row['Mostrando profesionalidad'] !== null && row['Mostrando profesionalidad'] !== undefined
         ? (row['Mostrando profesionalidad'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 26',
        value: (row, field) => row['Competencias - Pregunta 26'] !== null && row['Competencias - Pregunta 26'] !== undefined
         ? (row['Competencias - Pregunta 26'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 27',
        value: (row, field) => row['Competencias - Pregunta 27'] !== null && row['Competencias - Pregunta 27'] !== undefined
         ? (row['Competencias - Pregunta 27'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 28',
        value: (row, field) => row['Competencias - Pregunta 28'] !== null && row['Competencias - Pregunta 28'] !== undefined
         ? (row['Competencias - Pregunta 28'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 29',
        value: (row, field) => row['Competencias - Pregunta 29'] !== null && row['Competencias - Pregunta 29'] !== undefined
         ? (row['Competencias - Pregunta 29'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Competencias - Pregunta 30',
        value: (row, field) => row['Competencias - Pregunta 30'] !== null && row['Competencias - Pregunta 30'] !== undefined
         ? (row['Competencias - Pregunta 30'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Media Motivaciones y expectativas',
        value: (row, field) => row['Media Motivaciones y expectativas'] !== null && row['Media Motivaciones y expectativas'] !== undefined
         ? (row['Media Motivaciones y expectativas'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Aspiración',
        value: (row, field) => row['Motivaciones y expectativas - Aspiración'] !== null && row['Motivaciones y expectativas - Aspiración'] !== undefined
         ? (row['Motivaciones y expectativas - Aspiración'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 1',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 1'] !== null && row['Motivaciones y expectativas - Pregunta 1'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 1'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 2',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 2'] !== null && row['Motivaciones y expectativas - Pregunta 2'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 2'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 3',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 3'] !== null && row['Motivaciones y expectativas - Pregunta 3'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 3'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Aprendizaje',
        value: (row, field) => row['Motivaciones y expectativas - Aprendizaje'] !== null && row['Motivaciones y expectativas - Aprendizaje'] !== undefined
         ? (row['Motivaciones y expectativas - Aprendizaje'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 4',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 4'] !== null && row['Motivaciones y expectativas - Pregunta 4'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 4'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 5',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 5'] !== null && row['Motivaciones y expectativas - Pregunta 5'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 5'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },
      {
        label: 'Motivaciones y expectativas - Movilidad',
        value: (row, field) => row['Motivaciones y expectativas - Movilidad'] !== null && row['Motivaciones y expectativas - Movilidad'] !== undefined
         ? (row['Motivaciones y expectativas - Movilidad'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },
      {
        label: 'Motivaciones y expectativas - Pregunta 6',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 6'] !== null && row['Motivaciones y expectativas - Pregunta 6'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 6'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },



      {
        label: 'Motivaciones y expectativas - Pregunta 7',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 7'] !== null && row['Motivaciones y expectativas - Pregunta 7'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 7'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },

      {
        label: 'Motivaciones y expectativas - Pregunta 8',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 8'] !== null && row['Motivaciones y expectativas - Pregunta 8'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 8'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },
      {
        label: 'Motivaciones y expectativas - Liderazgo',
        value: (row, field) => row['Motivaciones y expectativas - Liderazgo'] !== null && row['Motivaciones y expectativas - Liderazgo'] !== undefined
         ? (row['Motivaciones y expectativas - Liderazgo'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },
      {
        label: 'Motivaciones y expectativas - Pregunta 9',
        value: (row, field) => row['Motivaciones y expectativas - Pregunta 9'] !== null && row['Motivaciones y expectativas - Pregunta 9'] !== undefined
         ? (row['Motivaciones y expectativas - Pregunta 9'] + '').replace('.', ',') || field.default : '',
        default: '' // default if value function returns null or undefined
      },
      'PDI - Acción Pregunta 1',
      'PDI - Fecha Pregunta 1',
      'PDI - Comentarios Pregunta 1',
      'PDI - Acción Pregunta 2',
      'PDI - Fecha Pregunta 2',
      'PDI - Comentarios Pregunta 2',
      'PDI - Acción Pregunta 3',
      'PDI - Fecha Pregunta 3',
      'PDI - Comentarios Pregunta 3',
    ];
  }

  private static createReportCSVBasicData(source: ReportModel) {
    const response = new ReportCSV();
    response.evaluatedId = source.evaluatedId;
    response.evaluatedFullName = source.evaluatedFullName;
    response.evaluatedDepartmentName = source.evaluatedDepartmentName;
    response.evaluatedPositionName = source.evaluatedPositionName;
    response.evaluatedWorkPlaceName = source.evaluatedJobName;
    response.evaluatedJobName = source.evaluatedJobName;
    response.evaluatorId = source.evaluatorId;
    response.evaluatorFullName = source.evaluatorFullName;
    return response;
  }

  // you can move this method to a service
  public generateCSVDownloadLink(options: { filename: string, data: any[], columns: any[], encoding: string }): SafeUrl {
    const fields = options.columns;
    const opts = {
      fields,
      output: options.filename,
      delimiter: ';'
    };
    const csv = json2csv.parse(options.data, opts);
    return this.domSanitizer.bypassSecurityTrustUrl('data:text/csv,' + encodeURIComponent(csv));
  }

  /* public getCSVParse(options: { filename: string, data: any[], columns: any[], encoding: string }) {
    const csv_type = 'text/csv';
      const csv_extension = '.csv';
      const data = new Blob([options.data], {type: csv_type});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data);
        return;
      } else {
        const obj = window.URL.createObjectURL(data);NULL
        FileSaver.saveAs(obj, options.filename  + csv_extension);
      }
  } */

  public retrieveReportData(idCampaign: number): Observable<ReportModel[]> {
    return this.http.get<ReportModel[]>(`${environment.UrlCoop}/api/v1/kpi/report?c=${idCampaign}`);
  }

  exportCsv(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(environment.UrlCoop + '/api/v1/kpi/report', {observe: 'response', responseType: 'blob' as 'json'});
  }

  public parseReport(source: ReportModel[]) {
    const merged = this.mergeReport(source);
    const response = [];
    merged.forEach(report => {
      let evRow = ReportService.createReportCSVBasicData(report);
      evRow = this.fillReportCSV(report, evRow);
      evRow = this.calculateReportColumns(evRow);
      response.push(evRow);
    });
    return response;
  }

  private calculateReportColumns(source: ReportCSV) {
    // Potencial
    // tslint:disable-next-line: max-line-length
    source['Competencias - Viviendo los valores CPS'] = this.calculateMeanOfArray([source['Competencias - Pregunta 1'], source['Competencias - Pregunta 2'], source['Competencias - Pregunta 3'], source['Competencias - Pregunta 4'], source['Competencias - Pregunta 5']], 5);
    source['Competencias - Inspirando para movilizar'] = this.calculateMeanOfArray([source['Competencias - Pregunta 6'], source['Competencias - Pregunta 7'], source['Competencias - Pregunta 8'], source['Competencias - Pregunta 9'], source['Competencias - Pregunta 10']], 5);
    source['Competencias - Coordinando esfuerzos'] = this.calculateMeanOfArray([source['Competencias - Pregunta 11'], source['Competencias - Pregunta 12'], source['Competencias - Pregunta 13'], source['Competencias - Pregunta 14'], source['Competencias - Pregunta 15']], 5);
    source['Competencias - Transmitiendo con impacto'] = this.calculateMeanOfArray([source['Competencias - Pregunta 16'], source['Competencias - Pregunta 17'], source['Competencias - Pregunta 18'], source['Competencias - Pregunta 19'], source['Competencias - Pregunta 20']], 5);
    source['Competencias - Buscando el cambio'] = this.calculateMeanOfArray([source['Competencias - Pregunta 21'], source['Competencias - Pregunta 22'], source['Competencias - Pregunta 23'], source['Competencias - Pregunta 24'], source['Competencias - Pregunta 25']], 5);
    source['Mostrando profesionalidad'] = this.calculateMeanOfArray([source['Competencias - Pregunta 26'], source['Competencias - Pregunta 27'], source['Competencias - Pregunta 28'], source['Competencias - Pregunta 29'], source['Competencias - Pregunta 30']], 5);
    // Competencias
    source['Motivaciones y expectativas - Aspiración'] = this.calculateMeanOfArray([source['Motivaciones y expectativas - Pregunta 1'], source['Motivaciones y expectativas - Pregunta 2'], source['Motivaciones y expectativas - Pregunta 3']], 3);

    source['Motivaciones y expectativas - Aprendizaje'] = this.calculateMeanOfArray([source['Motivaciones y expectativas - Pregunta 4'], source['Motivaciones y expectativas - Pregunta 5']], 2);

    source['Motivaciones y expectativas - Movilidad'] = this.calculateMeanOfArray([source['Motivaciones y expectativas - Pregunta 6'], source['Motivaciones y expectativas - Pregunta 7'], source['Motivaciones y expectativas - Pregunta 8']], 3);

    source['Motivaciones y expectativas - Liderazgo'] = this.calculateMeanOfArray([source['Motivaciones y expectativas - Pregunta 9']], 1);
    // tslint:disable-next-line: max-line-length
    source['Media Competencias'] = this.calculateMeanOfArray([source['Competencias - Viviendo los valores CPS'], source['Competencias - Inspirando para movilizar'], source['Competencias - Coordinando esfuerzos'], source['Competencias - Transmitiendo con impacto'], source['Competencias - Buscando el cambio'], source['Mostrando profesionalidad']], 6);
    source['Media Motivaciones y expectativas'] = this.calculateMeanOfArray([source['Motivaciones y expectativas - Aspiración'], source['Motivaciones y expectativas - Aprendizaje'], source['Motivaciones y expectativas - Movilidad'], source['Motivaciones y expectativas - Liderazgo']], 4);

    source.totalMean = this.calculateMeanOfArray([source['Media Competencias'], source['Media Motivaciones y expectativas']], 2).toString().replace('.',',');
    return source;
  }

  private fillReportCSV(source: ReportModel, response: ReportCSV) {
    if (!source.questionnaire || !source.questionnaire.grades) {
      return response;
    }
    source.questionnaire.grades.forEach(grade => {
      if (grade.value === undefined) {
        grade.value = null;
      }
      switch (grade.questionId) {
        case 1:
          response['Competencias - Pregunta 1'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 2:
          response['Competencias - Pregunta 2'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 3:
          response['Competencias - Pregunta 3'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 4:
          response['Competencias - Pregunta 4'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 5:
          response['Competencias - Pregunta 5'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 6:
          response['Competencias - Pregunta 6'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 7:
          response['Competencias - Pregunta 7'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 8:
          response['Competencias - Pregunta 8'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 9:
          response['Competencias - Pregunta 9'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 10:
          response['Competencias - Pregunta 10'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 11:
          response['Competencias - Pregunta 11'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 12:
          response['Competencias - Pregunta 12'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 13:
          response['Competencias - Pregunta 13'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 14:
          response['Competencias - Pregunta 14'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 15:
          response['Competencias - Pregunta 15'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 16:
          response['Competencias - Pregunta 16'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 17:
          response['Competencias - Pregunta 17'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 18:
          response['Competencias - Pregunta 18'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 19:
          response['Competencias - Pregunta 19'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 20:
          response['Competencias - Pregunta 20'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 21:
          response['Competencias - Pregunta 21'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 22:
          response['Competencias - Pregunta 22'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 23:
          response['Competencias - Pregunta 23'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 24:
          response['Competencias - Pregunta 24'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 25:
          response['Competencias - Pregunta 25'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 26:
          response['Competencias - Pregunta 26'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 27:
          response['Competencias - Pregunta 27'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 28:
          response['Competencias - Pregunta 28'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 29:
          response['Competencias - Pregunta 29'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 30:
          response['Competencias - Pregunta 30'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 31:
          response['Motivaciones y expectativas - Pregunta 1'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 32:
          response['Motivaciones y expectativas - Pregunta 2'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 33:
          response['Motivaciones y expectativas - Pregunta 3'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 34:
          response['Motivaciones y expectativas - Pregunta 4'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 35:
          response['Motivaciones y expectativas - Pregunta 5'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 36:
          response['Motivaciones y expectativas - Pregunta 6'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 37:
          response['Motivaciones y expectativas - Pregunta 7'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 38:
          response['Motivaciones y expectativas - Pregunta 8'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 39:
          response['Motivaciones y expectativas - Pregunta 9'] = grade.value || grade.value === 0 ? grade.value : '';
          break;
        case 40:
          response['PDI - Acción Pregunta 1'] = '' + grade.value + '';
          response['PDI - Fecha Pregunta 1'] = '' + grade.dateValue;
          response['PDI - Comentarios Pregunta 1'] = grade.textValue;
          break;
        case 41:
          response['PDI - Acción Pregunta 2'] = '' + grade.value + '';
          response['PDI - Fecha Pregunta 2'] = '' + grade.dateValue;
          response['PDI - Comentarios Pregunta 2'] = grade.textValue;
          break;
        case 42:
          response['PDI - Acción Pregunta 3'] = '' + grade.value + '';
          response['PDI - Fecha Pregunta 3'] = '' + grade.dateValue;
          response['PDI - Comentarios Pregunta 3'] = grade.textValue;
          break;
      }
    });
    return response;
  }

  private mergeReport(source: ReportModel[]) {
    const response: ReportModel[] = [];
    source.forEach(row => {
      let find = false;
      response.forEach(resp => {
        if (row.evaluatedId === resp.evaluatedId && row.evaluatorId === resp.evaluatorId) {
          find = true;
          resp.questionnaire.grades = resp.questionnaire.grades.concat(row.questionnaire.grades);
        }
      });
      if (find === false) {
        response.push(row);
      }
    });
    return response;
  }

  private calculateMeanOfArray(source: any[], length: number) {
    let count = 0;
    let sum = 0;
    source.forEach(value => {
      if ((value != null && value !== '') || value === 0) {
        count++;
        sum += value;
      }
    });
    return length === count ? sum / count : '';
  }
}
