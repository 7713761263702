import { Injectable,  } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private cardVisible: Subject<string> = new Subject<string>();
  iscardVisible: string;

  constructor() { }

  public getVisibleCard () {
    return this.cardVisible;
  }

  public setVisibleCard(visibleCard: string) {
    this.iscardVisible = visibleCard;
    this.cardVisible.next(visibleCard);
  }

  public returnVisibleCard () {
    return this.iscardVisible;
  }

}
