import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ResultResponse } from '../../Models/results-response-interface';
import { EvaluationService } from '../../services/evaluation.service';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import { Category } from '../../questions/models/questionnaire-interface';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Label } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-bar-vertical',
  templateUrl: './bar-vertical.component.html',
  styleUrls: ['./bar-vertical.component.scss']
})
export class BarVerticalComponent implements OnInit, OnChanges {

  @Output() resultsChange: EventEmitter<boolean>;
  @Input() data: ResultResponse;
  @Input() isGraph1: Boolean;
  @Input() id: string;

  // DESKTOP
  barChartType: ChartType = 'bar';
  barChartXLabels: Label[];
  barChartYLabels: {};
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          callback: (value, index, values) => {
            return this.barChartYLabels[value];
          }
        }
      }]
    },
    tooltips: {
      position: 'nearest',
      callbacks: {
        label: (tooltipItem, data) => {
          if (tooltipItem.datasetIndex === 0) {// self
            const category = this.data.categories[tooltipItem.index];
            return 'Propio: ' + category.self.description;
          } else {
            const question = this.data.categories[tooltipItem.index].questions[tooltipItem.datasetIndex - 1];
            return question.name + ': ' + question.mean.description;
          }
        }
      }
    }
  };
  barChartLegend = false;
  barChartPlugins = [];
  barChartData: ChartDataSets[];
  barColors = [{
    backgroundColor: '#c5d8dc'
  }, {
    backgroundColor: '#97bdc5'
  }, {
    backgroundColor: '#3c8797'
  }, {
    backgroundColor: '#66b3c4'
  }];

  config: any = {
    pagination: {
      el: '.swiper-pagination',
    },
    paginationClickable: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30
  };

  constructor(private evaluationService: EvaluationService, config: NgbCarouselConfig) {
    this.resultsChange = new EventEmitter<boolean>();
    config.interval = 0;
    config.wrap = false;
  }

  ngOnInit() {
    // this.updateResults(false);
    this.myResults(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  updateResults(hasResults: boolean): void {
    this.resultsChange.emit(hasResults);
  }

  myResults(data: ResultResponse) {

    if (data) {
      this.createGraphData(data);
      this.createGraphDataMobile(data);
    }
  }

  createGraphData(data: ResultResponse) {
    // max globales
    const qMax = Math.max(...data.categories.map(category => category.questions.length));
    // etiquetas eje X
    this.barChartXLabels = data.categories.map(category => category.name);
    // etiquetas eje Y
    this.barChartYLabels = {};
    data.axis.forEach(a => {
      this.barChartYLabels[a.value] = a.description;
    });
    // valores barras
    this.barChartData = new Array(qMax).fill(null).map(n => {
      return {
        data: [],
        question: [],
        stack: '_qMeans'
      };
    });
    for (let qIdx = 0; qIdx < qMax; qIdx++) {
      for (let catIdx = 0; catIdx < data.categories.length; catIdx++) {
        const question = data.categories[catIdx].questions[qIdx];
        this.barChartData[qIdx].data[catIdx] = (question ? question.mean.value : null);
      }
    }
    // valor linea
    this.barChartData.unshift({
      type: 'line',
      borderColor: 'black',
      pointStyle: 'rectRot',
      borderWidth: 1,
      radius: 5,
      pointHoverRadius: 5,
      pointHitRadius: 8,
      fill: false,
      showLine: false,
      data: data.categories.map(category => category.self.value)
    });
  }

  createGraphDataMobile(data: ResultResponse) {

  }

}
