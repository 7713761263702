import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServerService } from '../../services/server.service';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit, OnDestroy {
  serverError = false;
  loginSubscription: Subscription;
  loginError = false;
  formLogIn = {
    user: '',
    password: ''
  };
  constructor(public serverService: ServerService, public sessionService: SessionService, public router: Router) { }

  ngOnInit() {
    // tslint:disable-next-line: max-line-length

    this.serverService.getHealth().subscribe(() => {}, error => {
      console.error('server error');
      this.serverError = true;
    });
  }

  login() {
    this.loginSubscription = this.sessionService
      .login(this.formLogIn)
      .subscribe(() => {
        this.router.navigate(['campaings']);
      },
        error => {
          console.log('error');
          this.loginError = true;
        });
  }
  ngOnDestroy(): void {
    if (typeof (this.loginSubscription) !== 'undefined') {
      this.loginSubscription.unsubscribe();
    }
  }
}
