import { Injectable, Input } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../Models/user-interface';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RolGuardsService implements CanActivate {
  user: User;
  userSubscription: Subscription;
  constructor(private router: Router, private userService: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      this.userSubscription = this.userService.getUser().subscribe(user => {
        this.user = user;
        if (user.roleName === 'ADMIN' || user.roleName === 'MANAGER') {
            this.router.navigate(['dashboard/charts']);
            return false;
        } else {
            this.router.navigate(['']);
            return false;
        }
      });
      return true;
    }
  }
}


