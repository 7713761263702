import { Component, OnInit, Input } from '@angular/core';
import {
  Result,
  ResultResponse
} from '../../Models/results-response-interface';
import { HomeComponent } from '../home/home.component';
import { EvaluationService } from '../../services/evaluation.service';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  @Input() results: Result;
  resultSubscription: Subscription;
  dataUser: ResultResponse;
  segment: ResultResponse;
  data: any;
  dataGeneral: any;
  optionsGeneral: any;
  options: any;
  segemtUser: number;
  categoryResult: string;
  labelChart: Array<string> = [];
  segmentsChart: Array<number> = [];
  tooltipChart: Array<string> = [];
  titleChart: string;
  chartArray: Array<Chart> = [];
  chart = [];

  realCharts = [];

  realChartsData = [];

  realChartsOptions = [];

  constructor(private evaluationService: EvaluationService) {}

  ngOnInit() {
  //   this.myResults();
   }

  // myResults() {
  //   this.resultSubscription = this.evaluationService
  //     .getResults()
  //     .subscribe(dataUser => {
  //       this.dataUser = dataUser;
  //       Object.entries(this.dataUser).forEach(([key, value]) => {
  //         this.segment = value;
  //         // Cada donut
  //         Object.entries(this.segment).forEach(([key2, donut]) => {
  //           this.realCharts.push({ categories: [], values: [], tooltips: [] });
  //           this.titleChart = donut.title;
  //           donut.segments.forEach(e => {
  //             this.realCharts[key2].categories.push(e.category);
  //             this.realCharts[key2].values.push(e.value);
  //             this.realCharts[key2].tooltips.push(e.tooltip);
  //             this.segmentsChart.push(e.value);
  //             this.labelChart.push(e.category);
  //             this.tooltipChart.push(e.tooltip);
  //           });
  //           this.realChartsData.push({
  //             tooltip: this.realCharts[key2].tooltips,
  //             labels: this.realCharts[key2].categories,
  //             datasets: [
  //               {
  //                 data: this.realCharts[key2].values,
  //                 backgroundColor: ['#1e4983', '#d2d745', '#8c70b1', '#66b3c4'],
  //                 hoverBackgroundColor: []
  //               }
  //             ]
  //           });
  //           this.realChartsOptions.push({
  //             legend: {
  //               display: true,
  //               position: 'bottom'
  //             },
  //             title: {
  //               display: true,
  //               text: this.titleChart,
  //               fontSize: 20
  //             },
  //             tooltips: {
  //               callbacks: {
  //                 title: function(tooltipItem, data) {
  //                   return data['tooltip'][tooltipItem[0]['index']];
  //                 }
  //               }
  //             }
  //             });
  //       });
  //     });
  //   })
  // }
}
