import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardsService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === '/login') {
      // logged in so return true
      if (localStorage.getItem('token')) {
        this.router.navigate(['']);
        return false;
      }
    } else if ( !localStorage.getItem('token') ) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }


}
