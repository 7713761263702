import { Component, OnInit } from '@angular/core';
import {CampaingService} from '../services/campaing.service';
import {CampaignDetalInterface, CampaignFieldsInterface, CampaignInterface, IndividualDateInterface} from '../Models/campaign-interface';
import {EmployeesService} from '../services/employees.service';
import {EmployeeAllInterface} from '../Models/employee-interface';
import {PropertyService} from '../services/property.service';
import {PropertyInterface, QuestionnarieInterface, RolesInterface} from '../Models/property-interface';
import {UserService} from '../services/user.service';
import {Subscription} from 'rxjs';
import {User} from '../Models/user-interface';

@Component({
  selector: 'app-campaings',
  templateUrl: './campaings.component.html',
  styleUrls: ['./campaings.component.scss']
})
export class CampaingsComponent implements OnInit {
  public hiModal = false;
  public campaignListActive: CampaignInterface[];
  public campaignListFinalized: CampaignInterface[];
  public userList: EmployeeAllInterface[];
  public userListOriginal: EmployeeAllInterface[];
  public showActive: boolean;
  public showFinalized: boolean;
  public showUsers: boolean;
  public showAdministration: boolean;
  public propertyModel: PropertyInterface;
  public rolesModel: RolesInterface[];
  public questionnaireList: QuestionnarieInterface[];

  public userSubscription: Subscription;
  public user: User;
  constructor(
    private campaignService: CampaingService,
    private employeeService: EmployeesService,
    private propertyService: PropertyService,
    private userService: UserService) {}

  ngOnInit() {
    this.showActive = true;
    this.showFinalized = false;
    this.showUsers = false;
    this.showAdministration = false;
    this.user = {
      active: false,
      departmentName: '',
      email: '',
      firstTimeLogin: false,
      fullName: '',
      gender: '',
      id: 0,
      jobName: '',
      lastName: '',
      manager: false,
      name: '',
      positionName: '',
      roleId: 0,
      roleName: '',
      workPlaceName: ''
    };
    this.campaignListActive = [];
    this.campaignListFinalized = [];
    this.userList = [];
    this.userListOriginal = [];
    this.rolesModel = [];
    this.questionnaireList = [];
    this.getCampaign();
    this.getUserLogged();
  }
  getCampaign() {
    this.campaignService.getCampaigns().subscribe(resp => {
      this.campaignListActive = resp.filter(c => c.status !== 'FINALIZADA');
      this.campaignListFinalized = resp.filter(c => c.status === 'FINALIZADA');
    });
  }

  getUsers(load = true) {
      this.employeeService.getAllEmployees().subscribe(resp => {
        this.userList = resp;
        this.userListOriginal = resp;
      });
  }

  getUserLogged() {
    this.userSubscription = this.userService.getUser().subscribe((user) => {
      this.user = user;
      if (this.user.roleName === 'ADMIN') {
        this.getUsers();
        this.getProperties();
        this.getRoles();
        this.getQuestionnaire();
      }
    });
  }

  getProperties() {
    this.propertyService.getAllProperties().subscribe(resp => {
      this.propertyModel = resp;
    });
  }
  getRoles() {
    this.propertyService.getRoles().subscribe(resp => {
      this.rolesModel = resp;
    });
  }
  getQuestionnaire() {
    this.propertyService.getQuestionnaires().subscribe(resp => {
      this.questionnaireList = resp;
    });
  }

}
