import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../Models/user-interface';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  evaluations: any;
  user: User;

  constructor(private http: HttpClient) {
  }

  getUser(idCampaign: number = 1) {
    return this.http
      .get<User>(`${environment.UrlCoop}/api/v1/user?c=${idCampaign}`);
  }
}
