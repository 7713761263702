import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {CampaignDetalInterface, CampaignInterface, EmployeeInterface, NoEmployeeInterface} from '../Models/campaign-interface';
import {EvaluatedEvaluatorInterface} from '../Models/evaluator-interface';
import { OptionsInterface } from '../Models/property-interface';

@Injectable({
  providedIn: 'root',
})
export class CampaingService {
  constructor(private http: HttpClient) {}

  getCampaigns() {
    return this.http.get<CampaignInterface[]>(
      `${environment.UrlCoop}/api/v1/campaign/all`
    );
  }

  postCampaign(body) {
    return this.http.post(
      `${environment.UrlCoop}/api/v1/campaign`, body
    );
  }

  editCampaign(body, id) {
    return this.http.put(
      `${environment.UrlCoop}/api/v1/campaign/${id}`, body
    );
  }

  getCampaignById(id: number) {
    return this.http.get<CampaignDetalInterface>(
      `${environment.UrlCoop}/api/v1/campaign/${id}`
    );
  }
  getListEvalatedEvaluator(id: number) {
    return this.http.get<EvaluatedEvaluatorInterface[]>(
      `${environment.UrlCoop}/api/v1/evaluation/evaluatedEvaluator?c=${id}`
    );
  }

  getStatus() {
    return this.http.get<string[]>(
      `${environment.UrlCoop}/api/v1/campaign/statuses`
    );
  }

  getEmployeesById(id: number) {
    return this.http.get<EmployeeInterface[]>(
      `${environment.UrlCoop}/api/v1/campaign/${id}/employees`
    );
  }

  getNoEmployeesById(id: number) {
    return this.http.get<NoEmployeeInterface[]>(
      `${environment.UrlCoop}/api/v1/campaign/${id}/noEmployees`
    );
  }
  uploadFile(body,idCampaign) {
    return this.http.post<OptionsInterface>(
      `${environment.UrlCoop}/api/v1/evaluation/loadEvaluations?c=${idCampaign}`, body
    );
  }

  addEmployeeCampaign(body, idCampaign) {
    return this.http.post(
      `${environment.UrlCoop}/api/v1/campaign/${idCampaign}`, body
    );
  }
}
