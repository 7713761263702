import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Question } from "../../models/questionnaire-interface";

@Component({
  selector: "app-text-question",
  templateUrl: "./text-question.component.html",
  styleUrls: ["./text-question.component.scss"],
})
export class TextQuestionComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() questionNum: number;
  @Input() categoryNum: number;
  @Output() isValid: EventEmitter<any>;

  textValue: string;
  textAreaMaxLength: number;

  constructor() {
    this.isValid = new EventEmitter();
    this.textAreaMaxLength = 1500;
  }

  ngOnInit() {}

  updateValue() {
    //this.question.answer.textValue = this.textValue;
    this.isValid.emit(true);
  }
  ngOnDestroy() {
    this.question.answer.textValue = this.textValue;
  }
}
