import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Participant } from '../../models/participants.model';


@Component({
  selector: 'app-matrix-modal',
  templateUrl: './matrix-modal.component.html',
  styleUrls: ['./matrix-modal.component.css']
})
export class MatrixModalComponent implements OnInit, OnChanges {


  @Input() callbackFunction: () => void;
  @Input() closeFunction: () => void;
  @Input() data: Participant[];

  dataTable: Participant[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.dataTable = [];
    this.data.forEach(d => {
      this.dataTable.push(d);
    });
    console.log(this.data);
  }

  ngOnChanges() {
  }

}
