import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Question } from "./../../../questions/models/questionnaire-interface";

@Component({
  selector: "app-suggest-question",
  templateUrl: "./suggest-question.component.html",
  styleUrls: ["./suggest-question.component.scss"],
})
export class SuggestQuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() questionNum: number;
  @Input() categoryNum: number;
  @Output() isValid: EventEmitter<any>;

  textValue: string;
  textAreaMaxLength: number;

  constructor() {
    this.isValid = new EventEmitter();
    this.textAreaMaxLength = 1500;
  }

  ngOnInit() {
    this.question.suggestionText = this.question.suggestionText.replace(
      /\n/g,
      "<br />"
    );
  }

  updateValue() {
    //this.question.answer.textValue = this.textValue;
    this.isValid.emit(true);
  }
  ngOnDestroy() {
    //this.question.answer.textValue = this.textValue;
  }
}
