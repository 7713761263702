import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [LoaderSpinnerComponent],
  declarations: [LoaderSpinnerComponent]
})
export class SharedModule { }
