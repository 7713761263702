import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CampaignDetalInterface,
  CampaignFieldsInterface,
  CampaignInterface,
  EmployeeInterface,
  EvaluatedsInterface,
  IndividualDateInterface, NoEmployeeInterface
} from '../../Models/campaign-interface';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CampaingService } from '../../services/campaing.service';
import { Router } from '@angular/router';
import { EmployeesService } from '../../services/employees.service';
import { EmployeeAllInterface } from '../../Models/employee-interface';

import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-table-employees',
  templateUrl: './table-employees.component.html',
  styleUrls: ['./table-employees.component.scss']
})
export class TableEmployeesComponent implements OnInit {

  public hiModal = false;
  public campaignList: CampaignInterface[];
  public campaignDetail: CampaignDetalInterface;
  @Input() employeeList: EmployeeInterface[];
  @Input() employeeListOriginal: EmployeeInterface[];
  @Input() noEmployeeList: NoEmployeeInterface[];
  @Input() idCampaign: number;
  @Output() loadEmployee = new EventEmitter();
  public startDate: NgbDateStruct;
  public endDate: IndividualDateInterface;
  public campaignFields: CampaignFieldsInterface;
  public editMode: boolean;
  public page = 1;
  public searchInput = '';
  public hiModalDelete = false;
  public txtModalDelete = '';
  public idUser: number;
  public evaluateds: EvaluatedsInterface;
  userForm: any;
  constructor(
    private campaignService: CampaingService,
    private router: Router,
    private employeeService: EmployeesService,
    private formBuilder: FormBuilder) { 
      this.userForm = this.formBuilder.group({
        evaluateds: ['', Validators.required]
      });
    }
  ngOnInit() {
    this.initVar();
    this.getCampaign();
  }
  public initVar() {
    this.campaignDetail = {
      description: '',
      endDate: null,
      loadState: '',
      stage: '',
      startDate: null,
      status: '',
      name: ''
    };
    this.startDate = {
      year: null,
      month: null,
      day: null
    };
    this.endDate = {
      year: null,
      month: null,
      day: null
    };
    this.campaignDetail.loadState = 'LOADED';
    this.campaignDetail.stage = 'ALL_EVALUATIONS';
    this.campaignDetail.status = 'INICIO';

    this.campaignFields = {
      name: false,
      startDate: false,
      endDate: false
    };
    this.editMode = false;
  }
  public hideModal() {
    this.hiModal = !this.hiModal;
  }
  getCampaign() {
    this.campaignService.getCampaigns().subscribe(resp => {
      this.campaignList = resp;
    });
  }

  parseDateToNgbDatepicker(field: string, d1: string) {
    this[field].year = Number(d1.split('-')[0]);
    this[field].month = Number(d1.split('-')[1]);
    this[field].day = Number(d1.split('-')[2]);
  }


  getTranslateStatus(status: string) {
    switch (status) {
      case 'INICIO':
        return 'En progreso';
      case 'VIGENTE':
        return 'Iniciada';
      case 'FINALIZADA':
        return 'Finalizada';
    }
  }
  public searchUser() {
    this.employeeList = this.employeeListOriginal.filter(user => {
      if (user.fullName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.departmentName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.jobName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.positionName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.workplaceName.toLowerCase().includes(this.searchInput.toLowerCase())) {
        return user;
      }
    });
  }

  public deleteUser(idUser: number) {
    this.employeeService.deleteEmployee(this.idCampaign, idUser).subscribe(resp => {
      
      this.loadEmployee.emit(true);
      this.hideModalAgree(this.idUser);
    });
  }

  public hideModalAgree(idUser: number) {
    this.hiModalDelete = !this.hiModalDelete;
    this.txtModalDelete = '¿Desea eliminar al usuario de la campaña actual?';

    this.idUser = idUser;
  }

  addEvaluateds() {
    const obj = {
      'evaluateds': this.userForm.get('evaluateds').value
    };
    this.campaignService.addEmployeeCampaign(obj, this.idCampaign).subscribe( resp => {
      this.hideModal();
      this.onClearAll();
      this.loadEmployee.emit(true);
    }, err => {
      this.hideModal();
    })
    event.preventDefault();
  }
  selectAll() {
    
    const selected = this.noEmployeeList.map(item => item.id);
    this.userForm.get('evaluateds').patchValue(selected);
  }

  public onClearAll() {
    this.userForm.get('evaluateds').patchValue([]);
  }
}
