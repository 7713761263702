// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   UrlCoop: 'http://35.180.57.140:8080',
//   OauthHeader: 'Y2xpZW50OnNlY3JldA=='
// };

// export const environment = {
//   production: false,
//   UrlCoop: 'http://coop.digital-experience.club',
//   OauthHeader: 'Y29vcC1icmFpbnMtY2xpZW50OlN0UjRUM3N5U18rQD9fQnI0MU5T'
// };

/* export const environment = {
  production: true,
  UrlCoop: "https://api.cps-coop.dev.digitalcc.es",
  OauthHeader: "Y2xpZW50OnNlY3JldA==",
}; */

export const environment = {
  production: true,
  UrlCoop: "https://api-webcps.digitalcc.es",
  OauthHeader: "Y2xpZW50OnNlY3JldA==",
};

/*
export const environment = {
  production: true,
  UrlCoop: 'http://localhost:8080',
  OauthHeader: 'Y2xpZW50OnNlY3JldA=='
}; */

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
