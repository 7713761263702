import {Component, OnInit, NgModule} from '@angular/core';
import {MatrixService} from '../../services/matrix.service';
import {MatrixChartModel} from '../../models/matrix-chart.model';
import {Subscription} from 'rxjs';

import { MatrixModalComponent } from '../matrix-modal/matrix-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [MatrixModalComponent],
  entryComponents: [MatrixModalComponent]
})

@Component({
  selector: 'app-matrix-chart',
  templateUrl: './matrix-chart.component.html',
  styleUrls: ['./matrix-chart.component.scss']
})
export class MatrixChartComponent implements OnInit {
  single: any[];
  multi: any[];

  // view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  matrixChart: MatrixChartModel;
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  matrixSubscription: Subscription;

  constructor(private matrixService: MatrixService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.matrixSubscription = this.matrixService.getMatrixData().subscribe(
      response => {
        response.chart.sort((a, b) => {
          return a.order - b.order;
        });
        if (response) {
          this.matrixChart = response;
        }
      }
    );
  }

  onSelect(event) {
    console.log(event);



    const modalRef = this.modalService.open(MatrixModalComponent, { centered: true });
    modalRef.componentInstance.data = event.extra.participants;

    modalRef.componentInstance.closeFunction = () => {
      /* this.modalService.dismissAll(); */

      modalRef.close();
    };
  }
}
