import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { EmployeeAllInterface } from 'src/app/Models/employee-interface';
import {EvaluatedEvaluatorInterface} from '../../Models/evaluator-interface';
import {QuestionnaireStatusInterface, QuestionnarieInterface} from '../../Models/property-interface';
import {EvaluationService} from '../../services/evaluation.service';

@Component({
  selector: 'app-table-list-evaluators',
  templateUrl: './table-list-evaluators.component.html',
  styleUrls: ['./table-list-evaluators.component.scss']
})
export class TableListEvaluatorsComponent implements OnInit {
  @Input() evaluatedEvaluatorList: EvaluatedEvaluatorInterface[];
  @Input() evaluatedEvaluatorListOriginal: EvaluatedEvaluatorInterface[];
  @Input() statusQuestionnaireList: QuestionnaireStatusInterface[];
  @Input() questionnaireList: QuestionnarieInterface[];
  @Input() userList: EmployeeAllInterface[];
  @Output() editEv = new EventEmitter();
  @Output() searchEv = new EventEmitter();
  public searchInput = '';
  public page = 1;
  public hiModal = false;
  constructor(private evaluationService: EvaluationService) { }

  ngOnInit() {
  }
  public editUser(ev: EvaluatedEvaluatorInterface) {
    const obj = {
      'evaluation' : ev,
      'search': this.searchInput
    }
    this.editEv.emit(obj);
  }

  public searchUser() {
    this.searchEv.emit(this.searchInput);
  }

}
