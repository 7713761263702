import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(
    private http: HttpClient
  ) {
  }

  getHealth() {
    return this.http.get<any>(`${environment.UrlCoop}/actuator/health`);
  }

}
