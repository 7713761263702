import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  Questionnaire,
  QuestionnaireResponse,
  Suggestion,
} from "../models/questionnaire-interface";

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  private evaluationsUrl = environment.UrlCoop + "/api/v1/questionnaire/";
  private suggestionsUrl = environment.UrlCoop + "/api/v1/questionnaire/ideas";

  constructor(private http: HttpClient) {}

  postQuestionnaire(
    evaluationId: number,
    answers: QuestionnaireResponse,
  ): Observable<any> {
    const questionsUrl = this.evaluationsUrl + evaluationId;

    return this.http.post<any>(questionsUrl, answers);
  }

  draftQuestionnaire(
    evaluationId: number,
    answers: QuestionnaireResponse,
  ): Observable<any> {
    const questionsUrl = this.evaluationsUrl + evaluationId + "?draft=true";

    return this.http.post<any>(questionsUrl, answers);
  }

  getQuestionnaire(evaluationId: number): Observable<Questionnaire> {
    const questionsUrl = this.evaluationsUrl + evaluationId;

    return this.http.get<Questionnaire>(questionsUrl);
  }

  getSuggestions(): Observable<Suggestion> {
    return this.http.get<Suggestion>(this.suggestionsUrl);
  }
}
