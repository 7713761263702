import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question, OptionSetOption} from '../../models/questionnaire-interface';
import {User} from '../../../Models/user-interface';
import {Subscription} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from '../../../services/session.service';

@Component({
    selector: 'app-action-question',
    templateUrl: './action-question.component.html',
    styleUrls: ['./action-question.component.scss']
})
export class ActionQuestionComponent implements OnInit {
    userSubscription: Subscription;
    user: User;
    @Input() question: Question;
    @Input() questionNum: number;
    @Input() categoryNum: number;
    @Input() status: string;
    @Input() groupName: string;
    @Output() valueUpdate: EventEmitter<any>;
    @Output() isValid: EventEmitter<any>;
    evaluatorName;
    textValue: string;
    selectedValue: any;
    model: NgbDateStruct;
    placeholder = '';
    obligatory = true;
    date: NgbDateStruct = this.calendar.getToday(); // July, 14 1789

    nextIndex: number;

    // control: FormControl = new FormControl('placeholder', Validators.minLength(4));

    constructor(private userService: UserService, private calendar: NgbCalendar,
                private sessionService: SessionService
    ) {
        this.valueUpdate = new EventEmitter();
        this.isValid = new EventEmitter();
    }

    // ToDo: Move to Utils
    isEmptyText(s: String) {
        return (s === undefined || s === null || s === '');
    }

    ngOnInit() {
        this.evaluator();
        this.selectedValue = {
            id: null,
            text: null,
            secondaryText: null,
            value: null,
            order: null
        }
        if (this.question.answer !== null) {

            this.question.optionSetOptions.forEach(el => {
                if ( this.question.answer.value === el.value) {
                    this.selectedValue = el;
                }
            });
            if (this.question.answer.dateValue) {
                const date = this.question.answer.dateValue.split('-');
                this.model = {
                    year: null,
                    month: null,
                    day: null
                };
                this.model.year = parseInt(date[0]);
                this.model.month = parseInt(date[1]);
                this.model.day = parseInt(date[2]);
            }

            /* if (this.validateQuestion()) {
                this.isValid.emit(true);
            } else {
                this.isValid.emit(false);
            } */
        }
        this.updateValue();

        this.nextIndex = 12 + this.question.optionSetOptions.length;
    }

    evaluator() {
        this.evaluatorName = this.sessionService.getUserData().fullName;
    }

    onKeydown(event, opt: OptionSetOption){
        if (event.key === 'Enter' || event.key === 'Spacebar' +
          '') {
            console.log(event);
            this.selectedValue = opt;
        }
    }
    updateValue(opt?) {
        if (this.selectedValue) {
            this.placeholder = this.selectedValue.secondaryText;
        }
        if (this.validateQuestion()) {
            this.question.answer.value = opt ? opt.value : this.selectedValue.value;
            this.question.answer.questionOptionId = opt ? opt.id : this.selectedValue.id;
            this.question.answer.dateValue = this.model ? this.model.day + '/' + this.model.month + '/' + this.model.year : '';
            this.valueUpdate.emit('test');
            this.isValid.emit(true);
        } else {
            this.isValid.emit(false);
        }


    }

    validateQuestion() {
        if (this.obligatory) {
            // Validate the select, the evaluator name & the comment
            return (
                this.selectedValue !== undefined
                && !this.isEmptyText(this.question.text)
                && !this.isEmptyText(this.evaluatorName)
                && this.model !== null   
            );
        } else {
            return true;
        }
    }

    getDisabled() {
        if ( this.status === 'FINISH' && this.groupName !== 'Plan de Desarrollo') {
            return true;
        } else {
            return false;
        }
    }

}
