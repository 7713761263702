import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {EmployeeAllInterface} from '../Models/employee-interface';
import {PropertyInterface, QuestionnaireStatusInterface, QuestionnarieInterface, RolesInterface} from '../Models/property-interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private http: HttpClient) {}

  getAllProperties() {
    return this.http.get<PropertyInterface>(
      `${environment.UrlCoop}/api/v1/options/allProperties`
    );
  }
  getRoles() {
    return this.http.get<RolesInterface[]>(
      `${environment.UrlCoop}/api/v1/options/roles`
    );
  }

  getQuestionnaires() {
    return this.http.get<QuestionnarieInterface[]>(
      `${environment.UrlCoop}/api/v1/questionnaire/allQuestionnaires`
    );
  }

  getStatusQuestionnaire () {
    return this.http.get<QuestionnaireStatusInterface[]>(
      `${environment.UrlCoop}/api/v1/options/statusEvaluation`
    );
  }

  createProperty(body: {}, option) {
    return this.http.post<any>(
      `${environment.UrlCoop}/api/v1/options/${option}`, body
    );
  }

  modifyProperty(body: {}, option) {
    return this.http.put<any>(
      `${environment.UrlCoop}/api/v1/options/${option}`, body
    );
  }
}
