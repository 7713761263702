import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { SessionService } from '../../services/session.service';
import { User } from '../../Models/user-interface';
import { Subscription } from 'rxjs';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  @Output() visibleCard: EventEmitter<string> = new EventEmitter();
  userSubscription: Subscription;
  user: User;
  currentToken: User;
  constructor(
    public sessionService: SessionService,
    public router: Router,
    public userService: UserService,
    private homeService: HomeService
  ) {}

  ngOnInit() {
    this.isAdmin();
  }
  isAdmin() {
    this.userSubscription = this.userService.getUser().subscribe(
      user => {
       this.user = user;
      }
    );
  }
  logout() {
    this.sessionService.logout();
    this.router.navigate(['login']);
  }
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
  setVisibleCard(card: string) {
    if ( this.router.url !== '/' ) {
      this.router.navigate(['']);
    }
    this.homeService.setVisibleCard(card);
  }

  onKeydown(event){
    if (event.key === 'Enter' || event.key === ' ' +
      '') {
        this.logout();
    }
  }
}
