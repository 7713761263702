import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { GaugeModule } from "angular-gauge";
// Import ng-circle-progress
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
// Import KonvaModule
import { KonvaModule } from "ng2-konva";
import { DashboardChartCircleComponent } from "./components/dashboard-chart-circle/dashboard-chart-circle.component";
import { DashboardChartFilterBoxComponent } from "./components/dashboard-chart-filter-box/dashboard-chart-filter-box.component";
import { DashboardChartFilterComponent } from "./components/dashboard-chart-filter/dashboard-chart-filter.component";
import { DashboardChartFrameComponent } from "./components/dashboard-chart-frame/dashboard-chart-frame.component";
import { DashboardChartNumberComponent } from "./components/dashboard-chart-number/dashboard-chart-number.component";
import { DashboardChartTextComponent } from "./components/dashboard-chart-text/dashboard-chart-text.component";
import { DashboardChartsComponent } from "./components/dashboard-charts/dashboard-charts.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MatrixChartComponent } from "./components/matrix-chart/matrix-chart.component";
import { MatrixModalComponent } from "./components/matrix-modal/matrix-modal.component";
import { MatrixComponent } from "./components/matrix/matrix.component";
import { TablaComponent } from "./components/tabla/tabla.component";

@NgModule({
  imports: [
    CommonModule,
    GaugeModule.forRoot(),
    BrowserAnimationsModule,
    KonvaModule,
    BrowserModule,
    NgbPaginationModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),

    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 60,
      outerStrokeWidth: 6,
      innerStrokeWidth: 6,
      outerStrokeColor: "#66b3c4",
      innerStrokeColor: "#e7e8ea",
      animation: true,
      showSubtitle: true,
      showUnits: false,
      showBackground: false,
      showInnerStroke: true,
      startFromZero: false,
    }),
    NgxChartsModule,
  ],
  providers: [],
  declarations: [
    DashboardComponent,
    MatrixComponent,
    DashboardChartsComponent,
    DashboardChartFrameComponent,
    DashboardChartCircleComponent,
    DashboardChartTextComponent,
    DashboardChartNumberComponent,
    DashboardChartFilterComponent,
    DashboardChartFilterBoxComponent,
    TablaComponent,
    MatrixChartComponent,
    MatrixModalComponent,
  ],
  exports: [
    DashboardComponent,
    DashboardChartsComponent,
    MatrixComponent,
    DashboardChartFrameComponent,
    DashboardChartCircleComponent,
    DashboardChartTextComponent,
    DashboardChartNumberComponent,
    DashboardChartFilterComponent,
    DashboardChartFilterBoxComponent,
    TablaComponent,
  ],
})
export class DashboardModule {}

export class AppModule {}
