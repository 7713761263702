import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SessionService } from '../../../services/session.service';
import {
  Answer,
  Questionnaire,
  QuestionnaireResponse,
} from '../../models/questionnaire-interface';
import { QuestionService } from '../../services/question.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit {
  evaluationId: number;
  questionnaire: Questionnaire;
  questionnaireSubscription: Subscription;
  urlParamSubscription: Subscription;
  questionnaireBarWidth: number;
  meetingDate: any;
  mtDate: any = {
    year: '',
    day: '',
    month: ''
  };
  // Estado del cuestionario: 0 Introducción, 1 Questionario, 2 Rellenado ,3 Finalizado.
  processStatus = 0;
  isSummaryVisible = false;
  currentQuestionNum = 0;
  currentCategory = 0;
  currentSubcategory = 0;
  currentTotalSubcategories = 0;
  currentTotalQuestion = 0;
  subcategoriesTotal = 0;
  doFinish = false;
  isCompleted = false;
  isFinished = false;
  canPass = false;
  content: any;
  hasNext = true;
  hasPrevious = false;
  showSuggestIcon = false;
  mean = null;
  modalref = null;
  suggestionsList = [];
  idCampaign: number;
  showModal: boolean;
  constructor(
    private questionService: QuestionService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.urlParamSubscription = this.route.params.subscribe((params) => {
      this.evaluationId = params['id'];

      this.idCampaign = params['idCampaign'];
    });
  }

  ngOnInit() {
    this.showModal = false;
    this.questionnaireSubscription = this.questionService
      .getQuestionnaire(this.evaluationId)
      .subscribe(
        (response) => {
          this.questionnaire = response;

          this.questionService.getSuggestions().subscribe((d) => {
            if (d.idQuestionnaires.indexOf(this.questionnaire.id) !== -1) {
              this.suggestionsList = d.idea;
              this.showSuggestIcon = true;
            } else {
              this.suggestionsList = [];
              this.showSuggestIcon = false;
            }
          });

          this.subcategoriesTotal = this.countSubcategories();

          this.questionnaireBarWidth =
            100 / this.questionnaire.categories.length;
          switch (this.questionnaire.categories[0].questions[0].typeName) {
            case 'ACTION':
              if (
                this.questionnaire.categories[0].questions[0].answer &&
                this.questionnaire.categories[0].questions[0].answer.value &&
                this.questionnaire.categories[0].questions[0].answer
                  .dateValue &&
                this.questionnaire.categories[0].questions[0].answer
                  .textValue &&
                this.questionnaire.categories[0].questions[0].required
              ) {
                this.canPass = true;
              } else {
                this.canPass = false;
              }
              break;
            case 'SELECT':
              if (
                this.questionnaire.categories[0].questions[0].answer &&
                this.questionnaire.categories[0].questions[0].required &&
                this.questionnaire.categories[0].questions[0].answer
                  .questionOptionId == null
              ) {
                this.canPass = false;
              } else {
                this.canPass = true;
              }
              break;
          }
          if (this.questionnaire.state === 'FINISH') {
            this.processStatus = 1;
              this.toggleSummary();
          }
          this.updateButtonsVisibility();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  startQuestionnaire() {
    this.processStatus = 1;
  }

  countSubcategories() {
    let cnt = 0;
    for (const c of this.questionnaire.categories) {
      for (const s of c.subcategories) {
        cnt++;
      }
    }
    return cnt;
  }

  canPassEvent(isValid) {
    if (this.questionnaire.groupSubcategories) {
      this.canPass = true;
    } else {
      if (
        this.questionnaire.categories[this.currentCategory].questions[
          this.currentQuestionNum
        ].required
      ) {
        this.canPass = isValid;
      } else {
        this.canPass = true;
      }
    }
  }

  previousQuestion() {
    this.canPass = true;
    if (this.questionnaire.groupSubcategories) {
      if (this.currentSubcategory > 0) {
        this.currentSubcategory--;
        this.currentTotalSubcategories--;
      } else if (this.currentCategory > 0) {
        this.currentCategory--;
        this.currentTotalSubcategories =
          this.questionnaire.categories[this.currentCategory].subcategories
            .length - 1;
        this.currentSubcategory = this.currentTotalSubcategories;
        const ctsb = this.currentTotalSubcategories > 0 ? this.currentTotalSubcategories - 1 : this.currentTotalSubcategories;
        this.currentQuestionNum = this.questionnaire.categories[
          this.currentCategory
        ].subcategories[ctsb].questions.length;
      }
      this.doFinish = false;
    } else {
      if (this.currentQuestionNum > 0) {
        this.currentQuestionNum--;
        this.currentTotalQuestion--;
      } else if (this.currentCategory > 0) {
        this.currentCategory--;
        this.currentTotalQuestion--;
        this.currentQuestionNum =
          this.questionnaire.categories[this.currentCategory].questions.length -
          1;
      }
    }
    this.updateButtonsVisibility();
    return;
  }

  nextQuestionForGroup() {
    const categoryQLength = this.questionnaire.categories.length;
    const categorySQLength = this.questionnaire.categories[this.currentCategory]
      .subcategories.length;
    // Si es la ultima subCategoria
    if (categorySQLength - 1 === this.currentSubcategory) {
      // Tenemos que pasar de categoria
      if (categoryQLength - 1 === this.currentCategory) {
        console.log('ERROR ULTIMA CATEGORIA Y SUBCATEGORIA');
        return;
      } else {
        this.currentSubcategory = 0;
        this.currentCategory++;
        this.currentTotalSubcategories++;
      }
    } else {
      this.currentSubcategory++;
      this.currentTotalSubcategories++;
    }
    if (
      categoryQLength - 1 === this.currentCategory &&
      categorySQLength - 1 === this.currentSubcategory
    ) {
      this.doFinish = true;
    } else {
      this.doFinish = false;
    }
  }

  nextQuestion() {
    if (this.questionnaire.groupSubcategories) {
      this.nextQuestionForGroup();
    } else {
      const categoryQLength = this.questionnaire.categories[
        this.currentCategory
      ].questions.length;
      const categoryLength = this.questionnaire.categories.length;
      if (this.currentQuestionNum < categoryQLength - 1) {
        this.currentQuestionNum++;
        this.currentTotalQuestion++;
      } else if (
        this.currentQuestionNum === categoryQLength - 1 &&
        this.currentCategory < categoryLength - 1
      ) {
        this.currentQuestionNum = 0;
        this.currentTotalQuestion++;
        this.currentCategory++;
      }

      if (this.currentTotalQuestion === this.questionnaire.questionsNum - 1) {
        this.doFinish = true;
      } else {
        this.doFinish = false;
      }
      if (
        this.questionnaire.categories[this.currentCategory].questions[
          this.currentQuestionNum
        ].required &&
        (this.questionnaire.categories[this.currentCategory].questions[
          this.currentQuestionNum
        ].answer == null ||
          this.questionnaire.categories[this.currentCategory].questions[
            this.currentQuestionNum
          ].answer.questionOptionId == null)
      ) {
        this.canPass = false;
      } else {
        this.canPass = true;
      }
    }
    this.updateButtonsVisibility();
    return;
  }

  toggleSummary() {
    let total = 0;
    let count = 0;

    if (this.questionnaire.groupSubcategories) {
      if (!this.isSummaryVisible) {
        this.isCompleted = true;
        this.questionnaire.categories.forEach((category) => {
          let totalC = 0;
          let countC = 0;
          category.subcategories.forEach((sc) => {
            sc.questions.forEach((question) => {
              if (question.answer != null && question.answer.value != null) {
                countC++;
                 totalC += question.answer.value;
              }
            });
          });
          category.mean = totalC / countC;
          if (category.weight) {
            count += category.weight * countC;
            total += category.weight * totalC;
          } else {
            count += countC;
            total += totalC;
          }
        });
        this.mean = ((total / count) / 6 ) * 100;
      }
      this.isSummaryVisible = !this.isSummaryVisible;
    } else {
      if (!this.isSummaryVisible) {
        this.isCompleted = true;
        this.questionnaire.categories.forEach((category) => {
          let totalC = 0;
          let countC = 0;
          category.questions.forEach((question) => {
            if (question.answer != null && question.answer.value != null) {
              countC++;
              totalC += question.answer.value;
            }
          });
          category.mean = ((totalC / countC) / 6 ) * 100;
          if (category.weight) {
            count += category.weight * countC;
            total += category.weight * totalC;
          } else {
            count += countC;
            total += totalC;
          }
        });
        this.mean = ((total / count) / 6 ) * 100;
        console.log(this.mean);
      }
      this.isSummaryVisible = !this.isSummaryVisible;
    }
  }

  processAnswers() {
    const answers: Answer[] = [];

    if (this.questionnaire.groupSubcategories) {
      this.questionnaire.categories.forEach((category) => {
        category.subcategories.forEach((subcategory) => {
          subcategory.questions.forEach((question) => {
            if (question.answer != null) {
              answers.push(question.answer);
            }
          });
        });
      });
    } else {
      this.questionnaire.categories.forEach((category) => {
        category.questions.forEach((question) => {
          if (question.answer != null) {
            answers.push(question.answer);
          }
        });
      });
    }
    return answers;
  }
  sendQuestionnaire() {
    const answers = this.processAnswers();
    const questionnaireResponse: QuestionnaireResponse = {
      meetingDate: this.meetingDate ? this.meetingDate : '',
      answers: answers,
      evaluationComment: this.questionnaire.evaluationComment ? this.questionnaire.evaluationComment : '',
    };

    this.closeModal();

    this.questionService
      .postQuestionnaire(this.evaluationId, questionnaireResponse)
      .subscribe(
        (response) => {
          this.isFinished = true;
          this.processStatus = 3;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  editQuestion(questionPosition) {
    let finded = false;
    this.currentCategory = questionPosition.category;
    this.currentSubcategory = questionPosition.subcategory;
    this.currentQuestionNum = questionPosition.question
      ? questionPosition.question
      : 0;

    if (this.questionnaire.groupSubcategories) {
      this.currentTotalSubcategories = 0;
      for (const c of this.questionnaire.categories) {
        if (!finded) {
          for (const sc of c.subcategories) {
            if (
              sc !==
              this.questionnaire.categories[this.currentCategory].subcategories[
                this.currentSubcategory
              ]
            ) {
              this.currentTotalSubcategories++;
            } else {
              finded = true;
              break;
            }
          }
        } else {
          break;
        }
      }
    }

    this.updateButtonsVisibility();
    this.toggleSummary();
  }

  updateMeetingDateValue() {
    const year = this.mtDate.year.toString();
    let month = this.mtDate.month.toString();
    let day = this.mtDate.day.toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    if (day.length === 1) {
      day = '0' + day;
    }

    this.meetingDate = day + '/' + month + '/' + year;
  }


  saveDraft() {
    const answers = this.processAnswers();
    const questionnaireResponse: QuestionnaireResponse = {
      answers: answers,
      evaluationComment: this.questionnaire.evaluationComment ? this.questionnaire.evaluationComment : '',
      meetingDate: ''
    };

    this.questionService
      .draftQuestionnaire(this.evaluationId, questionnaireResponse)
      .subscribe(
        (response) => {
          this.router.navigate(['/general', this.idCampaign]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  updateButtonsVisibility() {
    if (this.questionnaire.groupSubcategories) {
      const categoryQLength = this.questionnaire.categories.length;
      const categorySQLength = this.questionnaire.categories[
        this.currentCategory
      ].subcategories.length;
      this.hasNext = !(
        this.currentCategory === categoryQLength - 1 &&
        this.currentSubcategory === categorySQLength - 1
      );
      this.hasPrevious =
        this.currentSubcategory > 0 || this.currentCategory > 0;
    } else {
      const categoryQLength = this.questionnaire.categories[
        this.currentCategory
      ].questions.length;
      const categoryLength = this.questionnaire.categories.length;
      this.hasNext = !(
        this.currentQuestionNum === categoryQLength - 1 &&
        this.currentCategory === categoryLength - 1
      );
      this.hasPrevious =
        this.currentQuestionNum > 0 || this.currentCategory > 0;
    }
    window.scrollTo(0, 0);
  }

  finishQuestionnaire(modal?) {
    if (this.questionnaire.groupName === 'Plan de Desarrollo') {
      this.mtDate.year = new Date().getFullYear();
      this.mtDate.month = new Date().getMonth() + 1;
      this.mtDate.day = new Date().getDate();
      this.updateMeetingDateValue();
      this.openModal(modal);
    } else {
      this.hideModal();
    }
  }

  openModal(modal) {
    this.modalref = this.modalService.open(modal, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
  }

  closeModal() {
    if (this.modalref) {
      this.modalref.close();
    }
  }
  public hideModal() {
    this.showModal = !this.showModal;
  }
}
