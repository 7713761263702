import { Component, OnInit } from '@angular/core';
import {CampaignDetalInterface, CampaignInterface, EmployeeInterface, NoEmployeeInterface} from '../Models/campaign-interface';
import {CampaingService} from '../services/campaing.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  public hiModal = false;
  public campaignListActive: CampaignInterface[];
  public campaignListFinalized: CampaignInterface[];
  public campaignDetail: CampaignDetalInterface;
  public showActive: boolean;
  public idCampaign: number;

  public employeeList: EmployeeInterface[];
  public noEmployeeList: NoEmployeeInterface[];
  public employeeListOriginal: EmployeeInterface[];
  constructor(private campaignService: CampaingService, private actRouter: ActivatedRoute) {
    this.actRouter.params.subscribe(params => {
      this.idCampaign = params['id'];
    });
  }

  ngOnInit() {
    this.showActive = true;
    this.getCampaign();
    this.getEmployees();
    this.getNoEmployees();
  }
  getCampaign() {
    this.campaignService.getCampaigns().subscribe(resp => {
      this.campaignListActive = resp.filter(c => c.status !== 'FINALIZED');
      this.campaignListFinalized = resp.filter(c => c.status === 'FINALIZED');
    });
  }

  getEmployees() {
    this.campaignService.getEmployeesById(this.idCampaign).subscribe(resp => {
      this.employeeList = resp;
      this.employeeListOriginal = resp;
    });
  }

  getNoEmployees() {
    this.campaignService.getNoEmployeesById(this.idCampaign).subscribe(resp => {
      this.noEmployeeList = resp;
    });
  }

}
