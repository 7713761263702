import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MatrixModel} from '../models/matrix-model';
import {MatrixUserModel} from '../models/matrix-user-model';
import {MatrixCommentModel} from '../models/matrix-comment-model';
import {Observable} from 'rxjs';
import {MatrixChartModel} from '../models/matrix-chart.model';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {

  private matrixUrl = environment.UrlCoop + '/api/v1/admin/matrix';
  private matrixChartUrl = environment.UrlCoop + '/api/v1/matrix';

  constructor(
    private http: HttpClient
  ) {
  }

  getMatrixData(): Observable<MatrixChartModel> {

    const srvUrl = this.matrixChartUrl;

    return this.http.get<MatrixChartModel>(srvUrl);
  }

  getMatrixId(matrixId: number): Observable<MatrixUserModel[]> {

    const srvUrl = this.matrixUrl + '/users/' + matrixId;

    return this.http.get<MatrixUserModel[]>(srvUrl);
  }

  getMatrixComment(userId: number): Observable<MatrixCommentModel[]> {
    const srvUrl = this.matrixUrl + '/comment/' + userId;
    return this.http.get<MatrixCommentModel[]>(srvUrl);

  }

}
