import { Component, OnInit } from '@angular/core';
import {CampaignDetalInterface} from '../Models/campaign-interface';
import {ActivatedRoute} from '@angular/router';
import {CampaingService} from '../services/campaing.service';
import {EvaluatedEvaluatorInterface} from '../Models/evaluator-interface';
import {PropertyService} from '../services/property.service';
import {QuestionnaireStatusInterface, QuestionnarieInterface} from '../Models/property-interface';
import { EmployeesService } from '../services/employees.service';
import { EmployeeAllInterface } from '../Models/employee-interface';
import { EvaluationService } from '../services/evaluation.service';

@Component({
  selector: 'app-campaings-detail',
  templateUrl: './campaings-detail.component.html',
  styleUrls: ['./campaings-detail.component.scss']
})
export class CampaingsDetailComponent implements OnInit {
  public hiModal = false;
  public hiModal2 = false;
  public idCampaign: number;
  public detailCampaign: CampaignDetalInterface;
  public evaluatedEvaluatorList: EvaluatedEvaluatorInterface[];
  public evaluatedEvaluatorListOriginal: EvaluatedEvaluatorInterface[];
  public showList: boolean;
  public statusQuestionnaireList: QuestionnaireStatusInterface[];
  public questionnaireList: QuestionnarieInterface[];
  public fileToUpload: File;
  public userList: EmployeeAllInterface[];
  public showErrors: boolean;
  public txtErrors: string;
  public txtSuccessfull: string;
  public selectedEv: EvaluatedEvaluatorInterface;
  public editModal: boolean;
  public showUpload: boolean;
  public searchInput: string;
  constructor(
    private activateRouter: ActivatedRoute,
    private campaignService: CampaingService,
    private propertyService: PropertyService,
    private employeeService: EmployeesService,
    private evaluationService: EvaluationService) {
    this.activateRouter.params.subscribe(params => {
      this.idCampaign = params['id'];
    });
  }

  ngOnInit() {

    this.showList = false;
    this.showUpload = false;
    this.evaluatedEvaluatorList = [];
    this.evaluatedEvaluatorListOriginal = [];
    this.questionnaireList = [];
    this.userList = [];
    this.showErrors = false;
    this.txtErrors = '';
    this.txtSuccessfull = '';
    this.editModal = false;
    this.initEvaluation();
    this.getDetailCampaign();
    this.getListEvaluatedEvaluator();
    this.getStatusEvaluation();
    this.getQuestionnaire();
    this.getEmployees();
  }
  public getDetailCampaign() {
    this.campaignService.getCampaignById(this.idCampaign).subscribe(resp => {
      this.detailCampaign = resp;
    });

  }

  public getListEvaluatedEvaluator() {

    this.campaignService.getListEvalatedEvaluator(this.idCampaign).subscribe(resp => {
      this.evaluatedEvaluatorList = resp;
      this.evaluatedEvaluatorListOriginal = resp;
      if (this.searchInput !== null && this.searchInput !== '') {
        this.searchList();
      }
      this.searchInput = '';
    });
  }

  public getStatusEvaluation() {
    this.propertyService.getStatusQuestionnaire().subscribe( resp => {
      this.statusQuestionnaireList = resp;
    });
  }
  public uploadFile(){
    document.getElementById('fileInput').click();
  }

  getQuestionnaire() {
    this.propertyService.getQuestionnaires().subscribe(resp => {
      this.questionnaireList = resp;
    });
  }

  public showUploadModal(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  postMethod() {
    const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.campaignService.uploadFile(formData, this.idCampaign).subscribe( resp => {
      if (resp.code === 565) {
        this.showErrors = true;
        this.txtErrors = resp.msg;
      } else {
        this.showErrors = true;
        this.txtErrors = '';
        this.txtSuccessfull = resp.msg;
      }
      this.showUpload = !this.showUpload;
    });
    return false;
  }

  getEmployees() {
    this.employeeService.getAllEmployees().subscribe(resp => {
      this.userList = resp;
    });
  }
  public hideModal() {
    this.hiModal = !this.hiModal;
  }
  public hideModal2() {
    this.hiModal2 = !this.hiModal2;
  }
  public updateEv() {
    if (this.editModal) {
      this.evaluationService.updateEvaluation(this.selectedEv).subscribe(resp => {
        setTimeout(() => {
          this.getListEvaluatedEvaluator();
          this.hideModal();
        }, 50);
      });
    } else {
      this.evaluationService.createEvaluation(this.selectedEv, this.idCampaign).subscribe(resp => {
        setTimeout(() => {
          this.getListEvaluatedEvaluator();
          this.hideModal();
        }, 50);
      });
    }
  }
  public initEvaluation() {
    this.selectedEv = {
      evaluatedFullName: '',
      evaluatedId: null,
      evaluatorFullName: '',
      evaluatorId: null,
      id: null,
      questionnaireId: null,
      questionnaireIntroText: '',
      state: '',
      stateId: null,
      stateName: ''
    };
  }

  public getTitleModal() {
    if (this.editModal) {
      return 'Editar';
    } else {
      return 'Nueva';
    }
  }
  public getBtnModal() {
    if (this.editModal) {
      return 'Editar';
    } else {
      return 'Crear';
    }
  }

  public editEvaluation($event) {
    this.editModal = true;
    this.selectedEv = $event['evaluation'];
    this.searchInput = $event['search'];
    this.hideModal();
  }

  searchList() {
    this.evaluatedEvaluatorList = this.evaluatedEvaluatorListOriginal.filter( user => {
      if (user.evaluatedFullName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.evaluatorFullName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        user.questionnaireIntroText.toLowerCase().includes(this.searchInput.toLowerCase())) {
        return user;
      }
    });
  }
  getSearch(searchInput) {
    this.searchInput = searchInput;
    this.searchList();
  }
}
