import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-tab-bar",
  templateUrl: "./tab-bar.component.html",
  styleUrls: ["./tab-bar.component.scss"],
})
export class TabBarComponent implements OnInit {
  @Input() results;
  @Input() graphValues;

  colors1 = ["#6DCF8E", "#70C2BA", "#6DA4CF"];
  colors2 = ["#B8CC64", "#C2584C", "#29558F"];
  colors3 = ["#CCB464", "#BC4CC2", "#298F7A"];

  selectedTab = 0;
  allSubcategories = [];

  constructor() {}

  ngOnInit() {
    for (const c of this.results) {
      for (const sc of c.subcategories) {
        this.allSubcategories.push(sc);
      }
    }
  }

  selectTab(tab: number) {
    this.selectedTab = tab;
  }
}
