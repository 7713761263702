import {Injectable} from '@angular/core';
import {DashboardAdmin} from '../models/dashboard-admin-model';
import {KPI} from '../models/kpi-model';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EvaluatedKPIROW, FilterValues} from 'src/app/Models/dashboard-interface';
import {FilterSelect} from '../../Models/select-interface';
import { Participant } from '../models/participants.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient, private router: Router) {
  }

  getGauges() {
    return this.http.get<DashboardAdmin>(`${environment.UrlCoop}/api/v1/admin/dashboard`);
  }

  getKPIs(filter?: FilterValues[], idCampaign: number = 1) {
    const params = this.prepareKPISearchParams(filter);
    return this.http.get<KPI>(`${environment.UrlCoop}/api/v1/kpi?c=${idCampaign}`, { params: params });
  }

  getManagerKPIs(filter?: FilterValues[], idCampaign: number = 1) {
    const params = this.prepareKPISearchParams(filter);
    return this.http.get<KPI>(`${environment.UrlCoop}/api/v1/kpi/manager?c=${idCampaign}`, { params: params });
  }

  getEvaluatedKPIs(filter?: FilterValues[], idCampaign: number = 1) {
    const params = this.prepareKPISearchParams(filter);
    return this.http.get<EvaluatedKPIROW>(`${environment.UrlCoop}/api/v1/kpi/table/evaluated?c=${idCampaign}`, { params: params });
  }

  getEvaluatedById(id: number, idCampaign: number) {
    return this.http.get<Participant[]>(`${environment.UrlCoop}/api/v1/kpi/table/evaluated/detail/${id}?c=${idCampaign}`);
  }
  getEvaluatedKPIsManager(filter?: FilterValues[]) {
    const params = this.prepareKPISearchParams(filter);
    return this.http.get<EvaluatedKPIROW>(`${environment.UrlCoop}/api/v1/kpi/table/evaluated/manager`, { params: params });
  }

  getSelectsForKPIs(idCampaign: number = 1) {
    return this.http.get<FilterSelect[]>(`${environment.UrlCoop}/api/v1/kpi/select?c=${idCampaign}`);
  }

  private prepareKPISearchParams(filters?: FilterValues[]): HttpParams {
    let params = new HttpParams();

    if (filters) {
      filters.forEach(filter => {
        if (filter.id1 && filter.id2 && filter.id2.length) {
          filter.id2.forEach(id2 => {
            params = params.append(filter.id1, id2);
          });
        }
      });
    }

    return params;
  }

  downloadExcel(filter?: FilterValues[], idCampaign?: number) {
    const params = this.prepareKPISearchParams(filter);
    return this.http.get<EvaluatedKPIROW>(`${environment.UrlCoop}/api/v1/kpi/table/evaluated/excel?c=${idCampaign}`, { params: params });
  }


}
