import { Component, OnInit, Input } from '@angular/core';
import { CircleKPI } from '../../models/kpi-model';

@Component({
  selector: 'app-dashboard-chart-circle',
  templateUrl: './dashboard-chart-circle.component.html',
  styleUrls: ['./dashboard-chart-circle.component.scss']
})
export class DashboardChartCircleComponent implements OnInit {
  @Input() data: CircleKPI;
  @Input() index: number;

  constructor() { }

  ngOnInit() {

  }

}
