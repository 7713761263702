import {Pipe, PipeTransform} from '@angular/core';
import {Grade} from '../models/questionnaire-interface';

@Pipe({
    name: 'round'
})
export class RoundNumberPipe implements PipeTransform {

    transform(value: number, limits: Array<Grade>): String {
        console.log(value)
        for (let index = 0; index < limits.length; index++) {
          if (
            value >= limits[index].min
            && value < limits[index].max
          ) {
            return limits[index].text;
          }
        }
        return limits[limits.length - 1].text;
      }
}
