import { SessionService } from './../../services/session.service';
import { UserService } from './../../services/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { EvaluatedCard } from "../../Models/evaluated-card.interface";
import { QuestionnaireComponent } from "../../questions/components/questionnaire/questionnaire.component";
import { EvaluationService } from "./../../services/evaluation.service";

@Component({
  selector: "app-evaluated",
  templateUrl: "./evaluated.component.html",
  styleUrls: ["./evaluated.component.scss"],
})
export class EvaluatedComponent implements OnInit {
  @Input() evaluation: EvaluatedCard;
  @Input() index: number;
  @Input() maxAndMinValues;
  @Input() idCampaign: number;
  index1: number;
  index2: number;
  questIds: number[];
  currentGraphicData;
  multiChart: boolean;
  userId: number;

  modalref;

  ifIsCompleted: QuestionnaireComponent;
  //  isCompleted: boolean;

  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  evaluationSubscription: Subscription;

  constructor(
    private evalSrv: EvaluationService,
    private modalService: NgbModal,
    private sessionSrv: SessionService
  ) {}

  ngOnInit() {
    this.index1 = 1;
    this.index2 = 2;
    this.countChanged.emit(
      this.index +
        this.index1 +
        this.index2 +
        this.evaluation.questionnaires.length -
        1
    );

    this.userId = this.sessionSrv.getUserData().id;
  }

  openUserGraphic(modal) {
    this.currentGraphicData = [];
    const results = this.evaluation.resultOut;
    if(results.length === 1) {
      this.multiChart = false;
      for (const c of results[0].categories) {
        for (const sc of c.subcategories) {
          this.currentGraphicData.push(sc);
        }
      }
    }else {
      this.multiChart = true;
      for (const r of results) {
        const multiResults = [];
        const autoev = r.isAutoEvaluation;
        for (const c of r.categories) {
          for (const sc of c.subcategories) {
            multiResults.push({
              values: sc,
              autoEv: autoev
            });
          }
        }
        this.currentGraphicData.push(multiResults);
      }
    }
    this.modalref = this.modalService.open(modal, {
      backdrop: "static",
      centered: true,
      size: "lg",
    });
  }

  downloadReport(ids, autoEv) {
    this.evalSrv
      .getEvaluationsPdf(this.evaluation.evaluated.id, ids)
      .subscribe((response) => {
        const type = autoEv ? 'autoevaluación' : 'evaluación';
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("type", "hidden");
        a.href =
          "data:application/pdf;base64," +
          // tslint:disable-next-line: no-string-literal
          response["pdf"];
        // tslint:disable-next-line: no-string-literal
        a.download = "Informe de " + type + " de " + this.evaluation.evaluated.fullName + ".pdf";
        a.click();
        document.body.removeChild(a);
      });
  }

  closeModal() {
    this.modalref.close();
  }
}
