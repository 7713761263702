import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KPI } from '../../models/kpi-model';

@Component({
  selector: 'app-dashboard-chart-frame',
  templateUrl: './dashboard-chart-frame.component.html',
  styleUrls: ['./dashboard-chart-frame.component.scss']
})
export class DashboardChartFrameComponent implements OnInit {
  @Input() data: KPI;
  @Input() index: number;
  @Input() idCampaign: number;
  @Output() countChanged: EventEmitter<number> =   new EventEmitter();
  indexTitle: number;
  index1: number;
  constructor() { }

  ngOnInit() {
    this.indexTitle = this.index;
    this.index1 = this.index + 1;
    this.countChanged.emit(this.index1 + 1);
  }

}
