import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GaugeModule } from 'angular-gauge';
import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'primeng/chart';
import { AppComponent } from './app.component';
import { CampaingsComponent } from './campaings/campaings.component';
import { BarVerticalComponent } from './components/bar-vertical/bar-vertical.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { EvaluatedComponent } from './components/evaluated/evaluated.component';
import { HomeComponent } from './components/home/home.component';
import { HorizontalBarMeanComponent } from './components/horizontal-bar-mean/horizontal-bar-mean.component';
import { HorizontalBarComponent } from './components/horizontal-bar/horizontal-bar.component';
import { LogInComponent } from './components/log-in/log-in.component';
import { MainComponent } from './components/main/main.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { RememberPasswordComponent } from './components/remember-password/remember-password.component';
import { ReplyTokenComponent } from './components/reply-token/reply-token.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { MatrixModalComponent } from './dashboard/components/matrix-modal/matrix-modal.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthGuardsService } from './guards/auth-guards.service';
import { RolGuardsService } from './guards/rol-guards.service';
import { QuestionsModule } from './questions/questions.module';
import { routes } from './routes';
import { EvaluationService } from './services/evaluation.service';
import { ServerService } from './services/server.service';
import { SessionService } from './services/session.service';
import { UserService } from './services/user.service';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './token-interceptor';
import { LegendComponent } from './components/legend/legend.component';
import { NgbDateCustomParserFormatter } from './formatter/ngb.formatter';
import { CampaingsDetailComponent} from './campaings-detail/campaings-detail.component';
import { CampaingService} from './services/campaing.service';
import { TableCampaignsComponent } from './components/table-campaigns/table-campaigns.component';
import { TableCampaignsFinalizedComponent} from './components/table-campaigns-finalized/table-campaigns-finalized.component';
import { EmployeesComponent } from './employees/employees.component';
import { TableEmployeesComponent } from './components/table-employees/table-employees.component';
import { TableEmployeesAllComponent } from './components/table-employees-all/table-employees-all.component';
import { EmployeesService } from './services/employees.service';
import { PropertyService } from './services/property.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {IconsModule} from './icons/icons.module';
import { AdministrationComponent } from './components/administration/administration.component';
import { TableListEvaluatorsComponent } from './components/table-list-evaluators/table-list-evaluators.component';

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    ReplyTokenComponent,
    HomeComponent,
    NavBarComponent,
    EvaluatedComponent,
    HorizontalBarMeanComponent,
    MainComponent,
    DoughnutChartComponent,
    BarVerticalComponent,
    RememberPasswordComponent,
    CampaingsComponent,
    CampaingsDetailComponent,
    TabBarComponent,
    HorizontalBarComponent,
    LegendComponent,
    TableCampaignsComponent,
    TableCampaignsFinalizedComponent,
    EmployeesComponent,
    TableEmployeesComponent,
    TableEmployeesAllComponent,
    AdministrationComponent,
    TableListEvaluatorsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes),
    NgbModule.forRoot(),
    GaugeModule.forRoot(),
    QuestionsModule,
    NgxChartsModule,
    SharedModule,
    BrowserModule,
    ChartModule,
    DashboardModule,
    BrowserAnimationsModule,
    ChartsModule,
    NgSelectModule,
    IconsModule,
    ReactiveFormsModule 
  ],
  providers: [
    ServerService,
    SessionService,
    UserService,
    AuthGuardsService,
    EvaluationService,
    RolGuardsService,
    CampaingService,
    EmployeesService,
    PropertyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ],

  entryComponents: [MatrixModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
