import {Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges} from '@angular/core';
import {DashboardService} from '../../../dashboard/services/dashboard.service';
import {Subscription} from 'rxjs';
import { FilterValues } from 'src/app/Models/dashboard-interface';
import { KPI } from '../../models/kpi-model';

@Component({
  selector: 'app-dashboard-charts',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.scss']
})
export class DashboardChartsComponent implements OnInit, OnChanges {

  @Input() managerMode: boolean;

  emptyGaugesData: any;
  dataGauges: Subscription;
  totalData: KPI[];
  labelValue: (value: number) => string;
  filterValues: FilterValues[];
  indexNumber: number;
  @Input() idCampaign: number;

  constructor(private dashboardService: DashboardService) {
    this.labelValue = function(value: number): string {
      return `${Math.round(value)} /${this.max}`;
    };
  }

  ngOnInit() {
    this.indexNumber = 14;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData(this.filterValues);
  }

  countChangedHandler(count: number) {
    this.indexNumber = count;
  }

  loadData(filter?: FilterValues[]) {
    const callback = data => {
      this.totalData = data;

      /* setTimeout(() => {

        const elements = document.getElementsByTagName('svg');
        for (let i = 0; i < elements.length; i++) {
          elements[i].setAttribute('focusable', 'false');
        }
      }, 100); */
    };
    if (this.managerMode) {
      this.dashboardService.getManagerKPIs(filter, this.idCampaign).subscribe(callback);
    } else {
      this.dashboardService.getKPIs(filter, this.idCampaign).subscribe(callback);
    }
  }

  filterValuesRequest(filter) {
    this.filterValues = filter;
    this.loadData(filter);
  }
}
