import { forEach } from '@angular/router/src/utils/collection';
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-horizontal-bar",
  templateUrl: "./horizontal-bar.component.html",
  styleUrls: ["./horizontal-bar.component.scss"],
})
export class HorizontalBarComponent implements OnInit {
  @Input() colors = ["#73D9B2", "#4caaa1", "#40c4e2"];

  @Input() data: any;

  @Input() multiChart: boolean = false;
  @Input() yValues;

  results: any[] = [];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  legendPosition: string = "below";
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  yAxisLabel: string = "Competencia";
  xAxisLabel = "Evaluacion";
  yAxisTicks = [];

  colorScheme = {
    domain: null,
  };

  schemeType: string = "linear";

  constructor() {}

  ngOnInit() {
    this.colorScheme.domain = this.colors;
    this.generateParseDataToChart();
    if(this.yValues){
      for (const v of this.yValues) {
        this.yAxisTicks.push(v.max);
      }
    }
  }

  generateParseDataToChart() {
    if(this.multiChart){
      this.data.forEach((e) => {
        e.forEach((f) => {
          this.results.push({
            name: f.values.name,
            series: [{
              value: Math.round(f.values.value),
              name: f.autoEv ? 'Autoevaluación' : 'Evaluación'
            }],
          })
        })
      });
      console.log(this.results)
    }else{
      this.data.forEach((e) => {
        this.results.push({
          name: e.name,
          value: Math.round(e.value),
        });
      });
    }
  }
}
