import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Question } from "../../models/questionnaire-interface";

@Component({
  selector: "app-radio-question",
  templateUrl: "./radio-question.component.html",
  styleUrls: ["./radio-question.component.scss"]
})
export class RadioQuestionComponent {
  @Input() question: Question;
  @Input() categoryNum: number;
  @Input() questionNum: number;
  @Output() isValid: EventEmitter<any>;
  checkSelecteds: any = [];

  constructor() {
    this.isValid = new EventEmitter();
  }
  ngOnInit(): void {
    console.log(this.question);
    this.checkSelecteds = this.question.answer.multiValue
      ? this.question.answer.multiValue
      : [];
  }
  updateValue($event) {
    const checkValue = $event.target.value;
    const i = this.checkSelecteds.indexOf(Number(checkValue));

    if (i === -1) {
      this.checkSelecteds.push(Number(checkValue));
    } else {
      this.checkSelecteds.splice(i, 1);
    }

    this.question.answer.multiValue = this.checkSelecteds;
  }

  isChecked(id: number) {
    let found = false;
    if (this.question.answer.multiValue) {
      this.question.answer.multiValue.forEach(mValue => {
        if (mValue === id) {
          found = true;
        }
      });
    }

    return found;
  }
}
