import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import {
  Answer,
  OptionSetOption,
  Question
} from "../../models/questionnaire-interface";

@Component({
  selector: "app-range-question",
  templateUrl: "./range-question.component.html",
  styleUrls: ["./range-question.component.scss"]
})
export class RangeQuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() questionNum: number;
  @Input() categoryNum: number;
  @Input() id: string;
  @Output() userClick: EventEmitter<any>;
  @Output() isValid: EventEmitter<any>;

  showSmiles = true;
  rangePosition: number;
  answer: Answer;
  maxValue: number;
  minValue: number;
  numbers: number[] = [];
  optionWidth: number;
  optionMargin: number;
  answerUser: boolean;
  contSlide: number;
  ariaRange: string;

  // Longitud question.optionSetOptions
  public questionLen: number;

  smileys: string[][] = [
    [
      "../../../../assets/images/ui-desktop-icon-very-sad.png",
      "../../../../assets/images/ui-desktop-icon-very-sad-disabled.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-sad.png",
      "../../../../assets/images/ui-desktop-icon-sad-disabled.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-normal.png",
      "../../../../assets/images/ui-desktop-icon-normal-disabled.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-happy.png",
      "../../../../assets/images/ui-desktop-icon-happy-disabled.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-very-happy.png",
      "../../../../assets/images/ui-desktop-icon-very-happy-disabled.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-very-very-happy.png",
      "../../../../assets/images/ui-desktop-icon-very-very-happy-disabled.png"
    ]
  ];
  smileysMvl: string[][] = [
    [
      "../../../../assets/images/ui-desktop-icon-very-sad@3x.png",
      "../../../../assets/images/ui-desktop-icon-very-sad-disabled@3x.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-sad@3x.png",
      "../../../../assets/images/ui-desktop-icon-sad-disabled@3x.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-normal@3x.png",
      "../../../../assets/images/ui-desktop-icon-normal-disabled@3x.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-happy@3x.png",
      "../../../../assets/images/ui-desktop-icon-happy-disabled@3x.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-very-happy@3x.png",
      "../../../../assets/images/ui-desktop-icon-very-happy-disabled@3x.png"
    ],
    [
      "../../../../assets/images/ui-desktop-icon-very-very-happy@3x.png",
      "../../../../assets/images/ui-desktop-icon-very-very-happy-disabled@3x.png"
    ]
  ];
  valueChanged = false;
  showConfirmModal = false;
  public config: SwiperConfigInterface = {
    navigation: true
  };

  constructor(private modalService: NgbModal) {
    this.userClick = new EventEmitter();
    this.isValid = new EventEmitter();
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  apperModal(content) {
    this.showConfirmModal = true;
    this.openVerticallyCentered(content);
  }

  // Modal
  confirmModal() {
    this.valueChanged = true;
  }

  validateQuestion() {
    return this.valueChanged;
  }

  ngOnInit() {
    this.rangePosition = 2;
    this.valueChanged = false;
    this.contSlide = 0;
    if (this.question.typeName === "RANGE") {
      this.maxValue = this.question.optionSetOptions[0].value;
      this.minValue = this.question.optionSetOptions[
        this.question.optionSetOptions.length
      ].value;
      for (let i = this.minValue; i <= this.maxValue; i++) {
        this.numbers.push(i);
      }
    } else if (this.question.typeName === "APPRECIATION") {
      this.minValue = 0;
      this.maxValue = this.question.optionSetOptions.length - 1;
      this.optionWidth = 100 / this.question.optionSetOptions.length;
      this.optionMargin = 100 / this.maxValue;
    }
    this.answerUser = false;
    if (this.question.answer.value != null && this.question.answer.value !== undefined) {
      this.rangePosition = this.question.answer.value - 1;
    }
    this.updateValue();
    this.questionLen = this.question.optionSetOptions.length;
    this.ariaRange = this.question.optionSetOptions[this.rangePosition].text;
  }

  updateValue() {
    this.valueChanged = true;
    this.question.answer.value = this.question.optionSetOptions[
      this.rangePosition
    ].value;
    this.question.answer.questionOptionId = this.question.optionSetOptions[
      this.rangePosition
    ].id;
    if (this.question.answer.value !== null) {
      this.answerUser = true;
    }
    this.isValid.emit(this.answerUser);
  }

  textOfNote(grade: number, options: OptionSetOption[]) {
    let text = "";
    options.sort((a, b) => {
      return a.order - b.order;
    });
    options.forEach((value, index) => {
      if (value.value === grade) {
        text = value.text;
      }
    });
    return text;
  }

  setAriaRange() {
    this.ariaRange = this.question.optionSetOptions[this.rangePosition].text;
  }
}
