import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { LoginResponse } from '../Models/login-response-interface';
import { Router } from '@angular/router';
import { User } from '../Models/user-interface';
import { PasswordResponse } from '../Models/password-response-interface';



@Injectable()
export class SessionService {

  private tokenHeaders = new HttpHeaders({
    Authorization: 'Basic ' + environment.OauthHeader,
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  handleError(e) {
    return throwError(e._body);
  }

  saveUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  login(user) {
    const body = new HttpParams()
      .set('username', user.user)
      .set('password', user.password)
      .set('grant_type', 'password');
    return this.http
      .post<LoginResponse>(`${environment.UrlCoop}/oauth/token`, body.toString(), { headers: this.tokenHeaders })
      .pipe(
        map(res => {
          if (res.access_token) {
              const myToken = {
                  token: res.access_token,
                  refresh : res.refresh_token
              };
              localStorage.setItem('token', JSON.stringify(myToken));
          }
        })
      );
  }

  getToken(): string {
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    return JSON.parse(token).token;
  }

  getRefreshToken(): string {
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    return JSON.parse(token).refresh;
  }

  logout() {
    this.router.navigate(['login']);
    localStorage.clear();
  }

  refreshToken() {
    const body = new HttpParams()
      .set('refresh_token', this.getRefreshToken())
      .set('grant_type', 'refresh_token');

      return this.http
      .post<LoginResponse>(`${environment.UrlCoop}/oauth/token`, body.toString(), { headers: this.tokenHeaders })
      .pipe(
        map(res => {
          if (res.access_token) {
              const myToken = {
                  token: res.access_token,
                  refresh : res.refresh_token
              };
              localStorage.setItem('token', JSON.stringify(myToken));
              return res.access_token;
          }
        }
      ));

  }

  removeToken() {
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }
  getUserData(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  sendPassword(user) {
    const bodyPassword = new HttpParams()
      .set('email', user.email);
      return this.http
      .post<PasswordResponse>(`${environment.UrlCoop}/api/v1/password`, bodyPassword.toString())
      .pipe(
        map(res => {
          console.log(res);
        })
      );
  }
}
