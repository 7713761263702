import { Component, OnInit, Input } from '@angular/core';
import { TextKPI } from '../../models/kpi-model';

@Component({
  selector: 'app-dashboard-chart-text',
  templateUrl: './dashboard-chart-text.component.html',
  styleUrls: ['./dashboard-chart-text.component.scss']
})
export class DashboardChartTextComponent implements OnInit {
  @Input() data: TextKPI;
  @Input() index: number;
  constructor() { }

  ngOnInit() {
  }

}
