export class ReportCSV {
  evaluatedId: any;
  evaluatedFullName: string;
  evaluatedDepartmentName: string;
  evaluatedPositionName: string;
  evaluatedWorkPlaceName: string;
  evaluatedJobName: string;
  evaluatorId: any;
  evaluatorFullName: string;
  // -------------- //
  totalMean: any;
  OneMean: any;
  cOneA: any;
  qOneA1: any;
  qOneA2: any;
  qOneA3: any;
  qOneA4: any;
  qOneA5: any;
  cOneB: any;
  qOneB1: any;
  qOneB2: any;
  qOneB3: any;
  qOneB4: any;
  qOneB5: any;
  cOneC: any;
  qOneC1: any;
  qOneC2: any;
  qOneC3: any;
  qOneC4: any;
  qOneC5: any;
  cOneD: any;
  qOneD1: any;
  qOneD2: any;
  qOneD3: any;
  qOneD4: any;
  qOneD5: any;
  cOneE: any;
  qOneE1: any;
  qOneE2: any;
  qOneE3: any;
  qOneE4: any;
  qOneE5: any;
  cOneF: any;
  qOneF1: any;
  qOneF2: any;
  qOneF3: any;
  qOneF4: any;
  qOneF5: any;

  TwoMean: any;
  cTwoA: any;
  qTwoA1: any;
  qTwoA2: any;
  qTwoA3: any;
  cTwoB: any;
  qTwoB1: any;
  qTwoB2: any;
  qTwoB3: any;
  cTwoC: any;
  qTwoC1: any;
  qTwoC2: any;
  qTwoC3: any;
  aP1Type: string;
  aP1Date: string;
  aP1Text: string;
  aP2Type: string;
  aP2Date: string;
  aP2Text: string;
  aP3Type: string;
  aP3Date: string;
  aP3Text: string;
}
